// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {}, // User object
    cart: [], // Current user's cart
    accessToken: '', // Access token
    refreshToken: '', // Refresh token
    isAdmin: false
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Store user data and tokens
        storeUser: (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.cart = action.payload.cart;
            state.isAdmin = action.payload.role === 'ADMIN';
        },
        editUser: (state, action) => {
            const { user, accessToken } = action.payload;
            state.user = { ...state.user, ...user };
            state.accessToken = accessToken;
        },
        // Log out the user
        logOut: (state) => {
            const preservedCart = state.cart;
            state.user = {};
            state.user.email = null;
            state.accessToken = '';
            state.refreshToken = '';
            state.cart = preservedCart;
            state.isAdmin = false
        },
        // Refresh the state (e.g., for token renewal)
        refreshState: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        // Set the access token
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
    },
});

export const { storeUser, logOut, refreshState, editUser, setAccessToken } = userSlice.actions;
export default userSlice.reducer;