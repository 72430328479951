import {useSelector} from "react-redux";

const UseOrderProcessing = () => {
    const cartItems = useSelector((state) => state.cart.cart);
    const userInfo = useSelector((state) => state.user.user);

    const orderProcessing = (orderInfo) => {
        const orderList = {
            address1: userInfo?.address1 === '' ? null : userInfo?.address1,
            address2: userInfo?.address2,
            apartment: userInfo?.apartment,
            city: userInfo?.city,
            state: userInfo?.state,
            zipcode: userInfo?.zipcode,
            email: userInfo.email,
            orderId: orderInfo?.orderId,
            projectAddress1: orderInfo?.projectAddress1,
            projectAddress2: orderInfo?.projectAddress2,
            projectCity: orderInfo?.projectCity,
            projectState: orderInfo?.projectState,
            projectZipcode: orderInfo?.projectZipcode,
            projectName: orderInfo?.projectName,
            purchaseOrder: orderInfo?.purchaseOrder,
            includeDrawerboxes: orderInfo?.includeDrawerboxes,
            includeHardware: orderInfo?.includeHardware,
            includeAssembly: orderInfo?.includeAssembly,
            includeFinishing: orderInfo?.includeFinishing,
            processingTxId: '',
            articles: [],
        };

        cartItems.forEach((item) => {
            if (item?.excludeFronts === 'true')
                item = {...item, frontMaterial: "Fronts By Others"}

            switch (item?.caseEdge) {
                case '0':
                    item = {...item, caseEdge: 'No Edgebanding'}
                    break;
                case '1':
                    item = {...item, caseEdge: '0.5 millimeter Edgebanding'}
                    break;
                default:
                    item = {...item, caseEdge: item?.caseEdge}

            }

            switch (item?.frontEdge) {
                case '0':
                    item = {...item, frontEdge: 'No Edgebanding'}
                    break;
                case '1':
                    item = {...item, frontEdge: '0.5 millimeter Edgebanding'}
                    break;
                default:
                    item = {...item, frontEdge: item?.frontEdge}

            }


            // const newArticle = {
            //     height: item.height?.length > 1 && item.height.charAt(1) === '-' ? item.height.charAt(0) : item.height,
            //     width: item.width?.length > 1 && item.width.charAt(1) === '-' ? item.width.charAt(0) : item.width,
            //     depth: item.depth?.length > 1 && item.depth.charAt(1) === '-' ? item.depth.charAt(0) : item.depth,
            //     caseMaterial: item.caseMaterial,
            //     frontMaterial: item.frontMaterial,
            //     drawerType: item?.drawerType,
            //     backPanel: item?.backPanel,
            //     gapTop: item?.gapTop,
            //     gapBottom: item?.gapBottom,
            //     gapLeft: item?.gapLeft,
            //     gapRight: item?.gapRight,
            //     gapCenter: item?.gapCenter,
            //     jointMethod: item?.jointMethod,
            //     serialNumber: item?.serialNumber,
            //     customerId: userInfo.email,
            //     quantity: item?.quantity,
            //     positionName: item?.positionName,
            //     edge1: item?.edge1,
            //     edge2: item?.edge2,
            //     edge3: item?.edge3,
            //     edge4: item?.edge4,
            //     numOfShelves: item?.numOfShelves,
            //     caseEdge: item?.caseEdge === '1' ? '0.5 millimeter Edgebanding' : item?.caseEdge,
            //     frontEdge: item?.frontEdge === '1' ? '0.5 millimeter Edgebanding' : item?.frontEdge,
            //     topDrwrHeightValue: item?.topDrwrHeightValue,
            //     bottomPanelConnector: item?.bottomPanelConnector,
            //     heightRange: item?.heightRange,
            //     widthRange: item?.widthRange,
            //     depthRange: item?.depthRange,
            //     drawers: item?.drawers,
            //     doors: item?.doors,
            //     gapControl: item?.gapControl,
            //     jointControl: item?.jointControl,
            //     adjShelves: item?.adjShelves,
            //     topDrwrHeight: item?.topDrwrHeight,
            //     backPanelMaterial: item?.backPanelMaterial,
            //     hingePlate: item?.hingePlate,
            //     legLevelers: item?.legLevelers,
            //     excludeFronts: item?.excludeFronts,
            //     filterTags: item?.filterTags,
            // };
            orderList.articles.push(item);
        });

        return orderList;
    };

    return {orderProcessing};
};

export default UseOrderProcessing;
