// import React, {useEffect, useRef, useState} from 'react';
// import {Group, Layer, Line, Rect, Stage, Text, Transformer} from 'react-konva';
// import './DrawingStyles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import NavbarComponent from '../NavBarComponents/NavbarComponent';
// import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import {useDispatch, useSelector} from "react-redux";
// import {OverlayTrigger, Tooltip} from "react-bootstrap";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft";
// import {updateCabinetsFromDrawingTool, updateItemsInCart} from "../../features/cart/cartSlice";
//
// const SNAP_DISTANCE = 5;
// const MIN_DIMENSION = 6;
// const MAX_DIMENSION = 96;
// const WALL_THICKNESS = 1;
//
// const DrawingTool = () => {
//     const initialCartItems = useSelector(state => state.cart.cart);
//     const [roomShape, setRoomShape] = useState('rectangular');
//     const [dimensions, setDimensions] = useState({width: 800, height: 600});
//     const [cabinets, setCabinets] = useState([]);
//     const [cartItems, setCartItems] = useState(initialCartItems);
//     const [hoveredCabinetIndex, setHoveredCabinetIndex] = useState(null);
//     const [menuVisible, setMenuVisible] = useState(false);
//     const [selectedId, setSelectedId] = useState(null);
//     const [tempCabinet, setTempCabinet] = useState({});
//     const [dimensionErrors, setDimensionErrors] = useState({});
//     const [inputValues, setInputValues] = useState({});
//     const [activeInput, setActiveInput] = useState(null);
//     const [positionNameCounters, setPositionNameCounters] = useState({});
//     const [positionNameMap, setPositionNameMap] = useState(new Map());
//     const dispatch = useDispatch();
//     const stageRef = useRef();
//     const transformerRef = useRef();
//     const dragItemRef = useRef();
//     const userLoggedIn = UserLoggedIn();
//
//
//     useEffect(() => {
//         const savedCabinets = JSON.parse(localStorage.getItem('savedCabinets'));
//
//         if (savedCabinets && savedCabinets.length > 0) {
//             setCabinets(savedCabinets);
//             const counters = {};
//             savedCabinets.forEach(cabinet => {
//                 if (cabinet.positionName) {
//                     const baseName = cabinet.positionName.replace(/\d+$/, '');
//                     const match = cabinet.positionName.match(/\d+$/);
//                     const count = match ? parseInt(match[0]) : 0;
//                     counters[baseName] = Math.max(counters[baseName] || 0, count);
//                 }
//             });
//             setPositionNameCounters(counters);
//             setCartItems(cartItems.filter(cartItem =>
//                 !savedCabinets.some(savedCabinet => savedCabinet.itemId === cartItem.itemId)
//             ));
//         }
//     }, []);
//
//     useEffect(() => {
//         if (selectedId && transformerRef.current) {
//             const selectedNode = stageRef.current.findOne('#' + selectedId);
//             if (selectedNode) {
//                 transformerRef.current.nodes([selectedNode]);
//                 transformerRef.current.getLayer().batchDraw();
//             }
//         } else if (transformerRef.current) {
//             transformerRef.current.nodes([]);
//             transformerRef.current.getLayer().batchDraw();
//         }
//     }, [selectedId]);
//
// // Replace the rest of the component with the exact same code, just update the useEffect above
//     useEffect(() => {
//         if (initialCartItems.length <= 0) {
//             setCabinets([]);
//             localStorage.removeItem('savedCabinets');
//         }
//     }, [cartItems]);
//
//     useEffect(() => {
//         if (cabinets.length > 0) {
//             localStorage.setItem('savedCabinets', JSON.stringify(cabinets));
//         }
//     }, [cabinets]);
//
//     const getNextPositionName = (positionName) => {
//         const originalPositionName = positionName;
//         let positionNamePrefix = positionName;
//         let positionNameNumber = 1;
//         let hasLeadingZeros = false;
//
//         const pattern = /(.*?)(_(\d+)|(\d+))?$/;
//         const match = pattern.exec(positionName);
//
//         if (match) {
//             positionNamePrefix = match[1];
//             if (match[3]) {
//                 positionNameNumber = parseInt(match[3], 10);
//                 hasLeadingZeros = match[3].startsWith('0');
//             } else if (match[4]) {
//                 positionNameNumber = parseInt(match[4], 10);
//             }
//         }
//
//         const relatedCabinets = cabinets.filter(cabinet =>
//             cabinet.positionName?.startsWith(positionNamePrefix)
//         );
//
//         const positionNameMap = new Set(relatedCabinets.map(cabinet => cabinet.positionName));
//         console.log('positionNameMap: ', positionNameMap)
//
//         let newPositionName;
//         if (match[3]) {
//             newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
//         } else {
//             newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
//         }
//
//         while (positionNameMap.has(newPositionName)) {
//             positionNameNumber++;
//             if (match[3]) {
//                 newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
//             } else {
//                 newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
//             }
//         }
//         return newPositionName;
//     };
//
//     const validateAllDimensions = (index) => {
//         const cabinet = cabinets[index];
//         const currentValues = inputValues[index] || cabinet;
//
//         let isValid = true;
//         let newInputValues = {...currentValues};
//
//         const [minWidth, maxWidth] = cabinet.widthRange.split('-').map(Number);
//         if (currentValues.width < minWidth || currentValues.width > maxWidth) {
//             setDimensionErrors(prev => ({
//                 ...prev,
//                 [index]: {
//                     ...prev[index],
//                     width: `Must be between ${minWidth}" and ${maxWidth}"`
//                 }
//             }));
//             newInputValues.width = cabinet.width;
//             isValid = false;
//         } else {
//             setDimensionErrors(prev => ({
//                 ...prev,
//                 [index]: {
//                     ...prev[index],
//                     width: null
//                 }
//             }));
//         }
//
//         const [minDepth, maxDepth] = cabinet.depthRange.split('-').map(Number);
//         if (currentValues.depth < minDepth || currentValues.depth > maxDepth) {
//             setDimensionErrors(prev => ({
//                 ...prev,
//                 [index]: {
//                     ...prev[index],
//                     depth: `Must be between ${minDepth}" and ${maxDepth}"`
//                 }
//             }));
//             newInputValues.depth = cabinet.depth;
//             isValid = false;
//         } else {
//             setDimensionErrors(prev => ({
//                 ...prev,
//                 [index]: {
//                     ...prev[index],
//                     depth: null
//                 }
//             }));
//         }
//
//         if (!isValid) {
//             setInputValues(prev => ({
//                 ...prev,
//                 [index]: newInputValues
//             }));
//         } else {
//             handleEditCabinet(index, newInputValues);
//         }
//
//         return isValid;
//     };
//
//     const handleEditCabinet = (index, newProps) => {
//         const groupId = cabinets[index]?.groupId;
//
//         setCabinets((prevCabinets) =>
//             prevCabinets.map((cabinet, i) => {
//                 if (groupId && cabinet.groupId === groupId) {
//                     return {
//                         ...cabinet,
//                         width: newProps.width ?? cabinet.width,
//                         depth: newProps.depth ?? cabinet.depth,
//                     };
//                 }
//                 return i === index ? {...cabinet, ...newProps} : cabinet;
//             })
//         );
//     };
//
//     const validateDimension = (index, name, value) => {
//         const cabinet = cabinets[index];
//         const range = name === 'width' ? cabinet.widthRange : cabinet.depthRange;
//         const [min, max] = range.split('-').map(Number);
//
//         if (value < min || value > max) {
//             setDimensionErrors(prev => ({
//                 ...prev,
//                 [index]: {
//                     ...prev[index],
//                     [name]: `Must be between ${min}" and ${max}"`
//                 }
//             }));
//             return false;
//         }
//
//         setDimensionErrors(prev => ({
//             ...prev,
//             [index]: {
//                 ...prev[index],
//                 [name]: null
//             }
//         }));
//         return true;
//     };
//
//     const handleInputChange = (index, e) => {
//         const {name, value} = e.target;
//         const numericValue = parseFloat(value);
//
//         setInputValues(prev => ({
//             ...prev,
//             [index]: {
//                 ...prev[index],
//                 [name]: numericValue
//             }
//         }));
//
//         validateDimension(index, name, numericValue);
//     };
//
//     const handleInputFocus = (index, name) => {
//         setActiveInput({index, name});
//     };
//
//     const handleInputBlur = (index, e) => {
//         const {name, value} = e.target;
//         const numericValue = parseFloat(value);
//
//         if (validateDimension(index, name, numericValue)) {
//             handleEditCabinet(index, {[name]: numericValue});
//         } else {
//             setInputValues(prev => ({
//                 ...prev,
//                 [index]: {
//                     ...prev[index],
//                     [name]: cabinets[index][name]
//                 }
//             }));
//         }
//         setActiveInput(null);
//     };
//
//     const handleMouseEnter = (index) => {
//         setHoveredCabinetIndex(index);
//         setMenuVisible(true);
//         setInputValues(prev => ({
//             ...prev,
//             [index]: {
//                 width: cabinets[index].width,
//                 depth: cabinets[index].depth
//             }
//         }));
//     };
//
//     const handleMouseLeave = () => {
//         setTimeout(() => {
//             if (!menuVisible) {
//                 if (hoveredCabinetIndex !== null) {
//                     validateAllDimensions(hoveredCabinetIndex);
//                 }
//                 setHoveredCabinetIndex(null);
//                 setDimensionErrors({});
//                 setInputValues({});
//             }
//         }, 1000);
//     };
//
//     const handleMenuMouseEnter = () => {
//         setMenuVisible(true);
//     };
//
//     const handleMenuMouseLeave = () => {
//         if (hoveredCabinetIndex !== null) {
//             validateAllDimensions(hoveredCabinetIndex);
//         }
//         setMenuVisible(false);
//     };
//
//     const handleDimensionChange = (e) => {
//         const {name, value} = e.target;
//         setDimensions((prev) => ({...prev, [name]: parseFloat(value)}));
//     };
//
//     const constrainToLShape = (x, y, width, depth, currentRotation) => {
//         const lShapeWidth = dimensions.width / 2;
//         const lShapeHeight = dimensions.height / 2;
//         let newX = x;
//         let newY = y;
//         let newRotation = currentRotation;
//
//         let effectiveWidth = currentRotation === 90 ? depth : width;
//         let effectiveDepth = currentRotation === 90 ? width : depth;
//
//         if (newX + effectiveWidth > lShapeWidth + 10 && newY < lShapeHeight - 10) {
//             newX = lShapeWidth;
//             newRotation = 90;
//         } else if (newX > dimensions.width) {
//             if (newY > lShapeHeight) {
//                 newX = dimensions.width;
//                 newRotation = 90;
//             }
//             if (newY < lShapeHeight) {
//                 newRotation = 0;
//                 newX = dimensions.width - effectiveWidth - WALL_THICKNESS;
//                 newY = lShapeHeight + WALL_THICKNESS;
//             }
//             if (newY + effectiveDepth > dimensions.height) {
//                 newY = dimensions.height - effectiveDepth - WALL_THICKNESS;
//             }
//         } else if ((newX > lShapeWidth) && (Math.abs(newY - effectiveDepth) - Math.abs(lShapeHeight - effectiveDepth)) <= SNAP_DISTANCE) {
//             newY = lShapeHeight + WALL_THICKNESS;
//             newRotation = 0;
//         } else if (newX <= 0 && newY >= 0) {
//             newX = WALL_THICKNESS;
//             newRotation = -90;
//         } else if (newY + effectiveDepth > dimensions.height) {
//             newY = dimensions.height - WALL_THICKNESS;
//             newRotation = 180;
//             if (newX - effectiveWidth <= 0) {
//                 newX = effectiveWidth + WALL_THICKNESS;
//             }
//         } else {
//             newRotation = 0;
//         }
//         if ((newY + effectiveDepth) > dimensions.height && (newX) > dimensions.width) {
//             newX = dimensions.width - effectiveWidth;
//             newY = dimensions.height + effectiveDepth + WALL_THICKNESS;
//         }
//
//         newX = Math.max(WALL_THICKNESS, Math.min(newX, dimensions.width - WALL_THICKNESS));
//         newY = Math.max(WALL_THICKNESS, Math.min(newY, dimensions.height - WALL_THICKNESS));
//
//         return {x: newX, y: newY, rotation: newRotation};
//     };
//
//     const constrainToRectangle = (x, y, width, depth, currentRotation) => {
//         let newX = x;
//         let newY = y;
//         let newRotation = currentRotation;
//         let effectiveWidth = currentRotation === 90 ? depth : width;
//         let effectiveDepth = currentRotation === 90 ? width : depth;
//         console.log('x: ', x)
//         console.log('y: ', y)
//
//         if (newX < 0) {
//             newRotation = -90;
//             newX = WALL_THICKNESS;
//             if (newY - effectiveWidth <= 0) {
//                 newX = 1;
//                 newY = effectiveWidth + WALL_THICKNESS;
//             }
//             if (newY >= dimensions.height) {
//                 newY = dimensions.height - WALL_THICKNESS;
//             }
//         } else if (newX === 1) {
//             if (newY + effectiveDepth >= dimensions.height) {
//                 newRotation = -180;
//                 newY = dimensions.height - WALL_THICKNESS;
//                 newX = newX + effectiveWidth;
//             }
//         } else if (newX > 1 && newY + effectiveDepth >= dimensions.height) {
//             newRotation = -180;
//             newY = dimensions.height - WALL_THICKNESS;
//             if (newX > dimensions.width) {
//                 newX = dimensions.width - WALL_THICKNESS;
//             }
//         } else if (newX + effectiveWidth > dimensions.width + 5) {
//             newRotation = 90;
//             newX = dimensions.width - WALL_THICKNESS;
//             if (newY < 0) {
//                 newY = WALL_THICKNESS;
//             }
//         } else if (newY <= 0) {
//             newRotation = 0;
//             newY = WALL_THICKNESS;
//             if (newX + effectiveWidth > dimensions.width) {
//                 newX = dimensions.width - effectiveWidth;
//                 newY = WALL_THICKNESS;
//             }
//         }
//         // else newRotation = 0;
//
//         return {x: newX, y: newY, rotation: newRotation};
//     };
//
//     const handleDragMove = (index, e) => {
//         let newX = e.target.x();
//         let newY = e.target.y();
//         const cabinetWidth = cabinets[index].width;
//         const cabinetDepth = cabinets[index].depth;
//         let newRotation = e.target.rotation();
//
//         if (roomShape === 'l-shaped') {
//             const constrainedPosition = constrainToLShape(newX, newY, cabinetWidth, cabinetDepth, newRotation);
//             newX = constrainedPosition.x;
//             newY = constrainedPosition.y;
//             newRotation = constrainedPosition.rotation;
//         } else if (roomShape === 'rectangular') {
//             const constrainedPosition = constrainToRectangle(newX, newY, cabinetWidth, cabinetDepth, newRotation);
//             newX = constrainedPosition.x;
//             newY = constrainedPosition.y;
//             newRotation = constrainedPosition.rotation;
//         } else {
//             newX = Math.max(WALL_THICKNESS, Math.min(newX, dimensions.width - cabinetWidth - WALL_THICKNESS));
//             newY = Math.max(WALL_THICKNESS, Math.min(newY, dimensions.height - cabinetDepth - WALL_THICKNESS));
//         }
//
//         cabinets.forEach((cabinet, i) => {
//             if (i !== index) {
//                 if (Math.abs(newX - (parseFloat(cabinet?.x) + parseFloat(cabinet?.width))) <= SNAP_DISTANCE) {
//                     newX = parseFloat(cabinet?.x) + parseFloat(cabinet.width);
//                 } else if (Math.abs(newX + cabinetWidth - parseFloat(cabinet?.x)) <= SNAP_DISTANCE) {
//                     newX = parseFloat(cabinet?.x) - cabinetWidth;
//                 }
//                 if (Math.abs(newY - (parseFloat(cabinet?.y) + parseFloat(cabinet?.depth))) <= SNAP_DISTANCE) {
//                     newY = parseFloat(cabinet?.y) + parseFloat(cabinet?.depth);
//                 } else if (Math.abs(newY + cabinetDepth - parseFloat(cabinet?.y)) <= SNAP_DISTANCE) {
//                     newY = parseFloat(cabinet.y) - cabinetDepth;
//                 }
//             }
//         });
//
//         e.target.x(newX);
//         e.target.y(newY);
//         e.target.rotation(newRotation);
//
//         const updatedCabinets = cabinets.map((cabinet, i) =>
//             i === index ? {...cabinet, x: newX, y: newY, rotation: newRotation} : cabinet
//         );
//         setCabinets(updatedCabinets);
//     };
//
//     const handleDragEnd = (index, e) => {
//         const newX = e.target.x();
//         const newY = e.target.y();
//         handleEditCabinet(index, {x: newX, y: newY});
//     };
//
//     const handleCartItemDragStart = (e, item) => {
//         if (item.quantity > 0) {
//             dragItemRef.current = item;
//         }
//     };
//
//     const handleStageDragOver = (e) => {
//         e.preventDefault();
//     };
//
//     const handleStageDrop = (e) => {
//         e.preventDefault();
//         const stage = stageRef.current;
//         if (stage && dragItemRef.current) {
//             stage.setPointersPositions(e);
//             const pointerPosition = stage.getPointerPosition();
//             const newPositionName = dragItemRef.current.positionName ?
//                 getNextPositionName(dragItemRef.current.positionName) :
//                 dragItemRef.current.positionName;
//
//             const groupId = dragItemRef.current?.groupId;
//
//             if (groupId !== null && cabinets.length > 0) {
//                 const foundCabinet = cabinets.find((cabinet) => cabinet?.groupId === groupId);
//                 if (foundCabinet) {
//                     dragItemRef.current.width = foundCabinet.width;
//                     dragItemRef.current.depth = foundCabinet.depth;
//                 }
//             }
//
//             const newCabinet = {
//                 ...dragItemRef.current,
//                 x: pointerPosition.x,
//                 y: pointerPosition.y,
//                 rotation: 0,
//                 id: Date.now(),
//                 positionName: newPositionName,
//                 isCopy: true
//             }
//
//             setCabinets([...cabinets, newCabinet]);
//
//             const updatedCartItems = cartItems.map(item =>
//                 item.itemId === dragItemRef.current.itemId
//                     ? {...item, quantity: item.quantity - 1}
//                     : item
//             ).filter(item => item.quantity > 0);
//
//             setCartItems(updatedCartItems);
//             dragItemRef.current = null;
//         }
//     };
//
//     const handleReturnToPanelClick = (index) => {
//         const cabinetToReturn = cabinets[index];
//         const existingCartItem = cartItems.find(item => item.itemId === cabinetToReturn.itemId);
//
//         if (existingCartItem) {
//             setCartItems(cartItems.map(item =>
//                 item.itemId === cabinetToReturn.itemId
//                     ? {...item, quantity: item.quantity + 1}
//                     : item
//             ));
//         } else {
//             setCartItems([...cartItems, {...cabinetToReturn, quantity: 1}]);
//         }
//
//         setCabinets(cabinets.filter((_, i) => i !== index));
//         setHoveredCabinetIndex(null);
//         setDimensionErrors({});
//         setInputValues({});
//         setMenuVisible(false);
//     };
//
//     const handleClearLayout = () => {
//         setCartItems(initialCartItems);
//         setCabinets([]);
//         setPositionNameCounters({});
//         localStorage.removeItem('savedCabinets');
//         setDimensionErrors({});
//         setInputValues({});
//         setMenuVisible(false);
//     };
//
//     const handleSaveChanges = () => {
//         dispatch(updateItemsInCart(cabinets.filter(cabinet => cabinet?.isCopy !== true)));
//         dispatch(updateCabinetsFromDrawingTool(cabinets))
//     };
//
//     const checkDeselect = (e) => {
//         const clickedOnEmpty = e.target === e.target.getStage();
//         const layer = e.target.getLayer();
//
//         const clickedOnBackground =
//             layer && (layer.findOne('Rect') === e.target || layer.findOne('Line') === e.target);
//
//         if (clickedOnEmpty || clickedOnBackground) {
//             setSelectedId(null);
//         }
//     };
//
//     const handleTransformEnd = (cabinet) => {
//         if (selectedId && transformerRef.current) {
//             const selectedNode = stageRef.current.findOne('#' + selectedId);
//             if (selectedNode) {
//                 const selectedCabinet = transformerRef.current.nodes([selectedNode]);
//                 cabinet.rotation = parseFloat(selectedCabinet.rotation().toFixed(2));
//                 // console.log('cabinet: ', cabinet)
//             }
//         }
//     };
//
//     const handleGroupClick = (id) => {
//         setSelectedId(id);
//         const foundCabinet = cabinets.find((cabinet) => parseInt(id) === cabinet.id);
//         console.log('cabinets: ', foundCabinet)
//     };
//     return (
//         <>
//             {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//             <div className="drawing-tool-container container-fluid">
//                 <div className="row">
//                     <div className="col-md-4">
//                         <div className="drawing-tool-controls card p-4 mb-4">
//                             <h2 className="drawing-tool-title mb-4">Layout Designer</h2>
//                             <div className="mb-3">
//                                 <label htmlFor="room-shape" className="form-label">Room Shape:</label>
//                                 <select
//                                     id="room-shape"
//                                     value={roomShape}
//                                     onChange={(e) => setRoomShape(e.target.value)}
//                                     className="form-select"
//                                 >
//                                     <option value="rectangular">Rectangular</option>
//                                     <option value="l-shaped">L Shaped</option>
//                                 </select>
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="room-width" className="form-label">Room Width (inches):</label>
//                                 <input
//                                     type="number"
//                                     id="room-width"
//                                     name="width"
//                                     value={dimensions.width}
//                                     onChange={handleDimensionChange}
//                                     className="form-control"
//                                 />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="room-height" className="form-label">Room Height (inches):</label>
//                                 <input
//                                     type="number"
//                                     id="room-height"
//                                     name="height"
//                                     value={dimensions.height}
//                                     onChange={handleDimensionChange}
//                                     className="form-control"
//                                 />
//                             </div>
//                         </div>
//                         <div className="cart-inventory-items-card card p-4 mt-4">
//                             <h3 className="cart-inventory-items-title mb-4">Cart Items</h3>
//                             <div className="cart-inventory-items-grid">
//                                 {cartItems.map((item) => (
//                                     <div
//                                         key={item.itemId}
//                                         className="cart-inventory-item"
//                                         draggable
//                                         onDragStart={(e) => handleCartItemDragStart(e, item)}
//                                     >
//                                         <div
//                                             className="cart-inventory-item-rectangle"
//                                             style={{
//                                                 width: '100%',
//                                                 height: `100%`,
//                                                 backgroundColor: '#e0e0e0',
//                                                 border: '1px solid #555',
//                                             }}
//                                         >
//                                             <span className="cart-inventory-item-text">
//                                                 {item?.positionName ? item.positionName.toUpperCase() : `${item.width}" x ${item.depth}"`}
//                                             </span>
//                                             <span className="cart-inventory-item-quantity">
//                                                 ({item.quantity})
//                                             </span>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-8">
//                         <button
//                             className="btn btn-dark save-chngs-btn"
//                             onClick={() => handleClearLayout()}
//                         >Clear Layout
//                         </button>
//                         <button
//                             className="btn btn-dark save-chngs-btn"
//                             onClick={() => handleSaveChanges()}>
//                             Save Changes
//                         </button>
//                         <div
//                             className="drawing-tool-stage-wrapper"
//                             onDragOver={handleStageDragOver}
//                             onDrop={handleStageDrop}
//                         >
//                             <Stage
//                                 width={dimensions.width}
//                                 height={dimensions.height}
//                                 ref={stageRef}
//                                 className="drawing-tool-stage"
//                                 onClick={checkDeselect}
//                             >
//                                 <Layer>
//                                     {roomShape === 'rectangular' && (
//                                         <Rect
//                                             x={0}
//                                             y={0}
//                                             width={dimensions.width}
//                                             height={dimensions.height}
//                                             fill="white"
//                                             stroke="black"
//                                             strokeWidth={WALL_THICKNESS}
//                                         />
//                                     )}
//                                     {roomShape === 'l-shaped' && (
//                                         <Line
//                                             points={[
//                                                 0, 0,
//                                                 dimensions.width / 2, 0,
//                                                 dimensions.width / 2, dimensions.height / 2,
//                                                 dimensions.width, dimensions.height / 2,
//                                                 dimensions.width, dimensions.height,
//                                                 0, dimensions.height,
//                                                 0, 0,
//                                             ]}
//                                             fill="white"
//                                             stroke="black"
//                                             strokeWidth={WALL_THICKNESS}
//                                             closed
//                                         />
//                                     )}
//                                     {cabinets.map((cabinet, index) => (
//                                         <Group
//                                             key={cabinet.id || cabinet.itemId}
//                                             id={cabinet.id.toString()}
//                                             x={cabinet.x}
//                                             y={cabinet.y}
//                                             rotation={cabinet.rotation || 0}
//                                             draggable
//                                             // onClick={() => setSelectedId(cabinet.id.toString())}
//                                             onClick={() => handleGroupClick(cabinet.id.toString())}
//                                             onDragMove={(e) => handleDragMove(index, e)}
//                                             onDragEnd={(e) => handleDragEnd(index, e)}
//                                             onMouseEnter={() => handleMouseEnter(index)}
//                                             onMouseLeave={handleMouseLeave}
//                                             onTransformEnd={() => handleTransformEnd(cabinet)}
//                                         >
//                                             <Rect
//                                                 width={parseFloat(cabinet?.width)}
//                                                 height={parseFloat(cabinet?.depth)}
//                                                 fill="#e0e0e0"
//                                                 stroke="#555"
//                                                 strokeWidth={1}
//                                             />
//                                             <Text
//                                                 // width={parseFloat(cabinet?.width) > 40 ? parseFloat(cabinet?.width) : 40}
//                                                 // height={parseFloat(cabinet?.depth)}
//                                                 width={parseFloat(cabinet?.width) > 40 ? parseFloat(cabinet?.width) : 40}
//                                                 // height={parseFloat(cabinet?.depth) > 40 ? parseFloat(cabinet?.depth) + 8 : 40}
//                                                 height={52}
//                                                 text={cabinet?.positionName}
//                                                 fontSize={8}
//                                                 fill="black"
//                                                 fontStyle="bold"
//                                                 align="center"
//                                                 verticalAlign="bottom"
//                                                 padding={10}
//                                                 y={parseFloat(cabinet?.depth) > 40 ? parseFloat(cabinet?.depth) - 16 : parseFloat(cabinet?.depth)}
//                                             />
//                                         </Group>
//                                     ))}
//                                     {selectedId && (
//                                         <Transformer
//                                             ref={transformerRef}
//                                             rotationSnaps={[0, 90, 180, 270]}
//                                             boundBoxFunc={(oldBox, newBox) => {
//                                                 return newBox;
//                                             }}
//                                             resizeEnabled={false}
//                                             rotation={90}
//                                         />
//                                     )}
//                                 </Layer>
//                             </Stage>
//
//                             {hoveredCabinetIndex !== null && menuVisible && (
//                                 <div
//                                     className="drawing-tool-cabinet-menu"
//                                     style={{
//                                         position: 'absolute',
//                                         left: `${
//                                             cabinets[hoveredCabinetIndex]?.x +
//                                             stageRef.current.container().offsetLeft +
//                                             parseFloat(cabinets[hoveredCabinetIndex]?.width) +
//                                             10
//                                         }px`,
//                                         top: `${
//                                             cabinets[hoveredCabinetIndex]?.y +
//                                             stageRef.current.container().offsetTop + parseFloat(cabinets[hoveredCabinetIndex]?.depth)
//                                         }px`,
//                                         zIndex: 100,
//                                         padding: '1rem',
//                                     }}
//                                     onMouseEnter={handleMenuMouseEnter}
//                                     onMouseLeave={handleMenuMouseLeave}
//                                 >
//                                     <div className="cabinet-menu-inputs-container">
//                                         <div className="cabinet-dimension-group">
//                                             <label htmlFor={`width-${hoveredCabinetIndex}`}
//                                                    className="cabinet-dimension-label">
//                                                 Width:
//                                             </label>
//                                             <input
//                                                 type="number"
//                                                 id={`width-${hoveredCabinetIndex}`}
//                                                 className={`form-control form-control-sm cabinet-dimension-input ${
//                                                     dimensionErrors[hoveredCabinetIndex]?.width ? 'is-invalid' : ''
//                                                 }`}
//                                                 name="width"
//                                                 value={inputValues[hoveredCabinetIndex]?.width ?? cabinets[hoveredCabinetIndex]?.width}
//                                                 onChange={(e) => handleInputChange(hoveredCabinetIndex, e)}
//                                                 onFocus={() => handleInputFocus(hoveredCabinetIndex, 'width')}
//                                                 onBlur={(e) => handleInputBlur(hoveredCabinetIndex, e)}
//                                                 step="0.001"
//                                             />
//                                             {dimensionErrors[hoveredCabinetIndex]?.width && (
//                                                 <div className="cabinet-dimension-error">
//                                                     {dimensionErrors[hoveredCabinetIndex]?.width}
//                                                 </div>
//                                             )}
//                                         </div>
//                                         <div className="cabinet-dimension-group">
//                                             <label htmlFor={`depth-${hoveredCabinetIndex}`}
//                                                    className="cabinet-dimension-label">
//                                                 Depth:
//                                             </label>
//                                             <input
//                                                 type="number"
//                                                 id={`depth-${hoveredCabinetIndex}`}
//                                                 className={`form-control form-control-sm cabinet-dimension-input ${
//                                                     dimensionErrors[hoveredCabinetIndex]?.depth ? 'is-invalid' : ''
//                                                 }`}
//                                                 name="depth"
//                                                 value={inputValues[hoveredCabinetIndex]?.depth ?? cabinets[hoveredCabinetIndex]?.depth}
//                                                 onChange={(e) => handleInputChange(hoveredCabinetIndex, e)}
//                                                 onFocus={() => handleInputFocus(hoveredCabinetIndex, 'depth')}
//                                                 onBlur={(e) => handleInputBlur(hoveredCabinetIndex, e)}
//                                                 step="0.001"
//                                             />
//                                             {dimensionErrors[hoveredCabinetIndex]?.depth && (
//                                                 <div className="cabinet-dimension-error">
//                                                     {dimensionErrors[hoveredCabinetIndex]?.depth}
//                                                 </div>
//                                             )}
//                                         </div>
//                                         <div className="cabinet-button-group">
//                                             <OverlayTrigger
//                                                 placement="top"
//                                                 overlay={<Tooltip id={`tooltip-${hoveredCabinetIndex}`}>Return to
//                                                     Panel</Tooltip>}
//                                             >
//                                                 <button
//                                                     className="btn btn-outline-secondary btn-sm cabinet-return-button"
//                                                     onClick={() => handleReturnToPanelClick(hoveredCabinetIndex)}
//                                                 >
//                                                     <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
//                                                 </button>
//                                             </OverlayTrigger>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
//
// export default DrawingTool;


import React, {useEffect, useRef, useState} from 'react';
import {Group, Layer, Line, Rect, Stage, Text, Transformer} from 'react-konva';
import './DrawingStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {useDispatch, useSelector} from "react-redux";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft";
import {updateCabinetsFromDrawingTool, updateItemsInCart} from "../../features/cart/cartSlice";
import jsPDF from 'jspdf'; // Added import for jsPDF

const SNAP_DISTANCE = 5;
const MIN_DIMENSION = 6;
const MAX_DIMENSION = 96;
const WALL_THICKNESS = 1;

const DrawingTool = () => {
    const initialCartItems = useSelector(state => state.cart.cart);
    const [roomShape, setRoomShape] = useState('rectangular');
    const [dimensions, setDimensions] = useState({width: 800, height: 600});
    const [cabinets, setCabinets] = useState([]);
    const [cartItems, setCartItems] = useState(initialCartItems);
    const [hoveredCabinetIndex, setHoveredCabinetIndex] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [tempCabinet, setTempCabinet] = useState({});
    const [dimensionErrors, setDimensionErrors] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [activeInput, setActiveInput] = useState(null);
    const [positionNameCounters, setPositionNameCounters] = useState({});
    const [positionNameMap, setPositionNameMap] = useState(new Map());
    const dispatch = useDispatch();
    const stageRef = useRef();
    const transformerRef = useRef();
    const dragItemRef = useRef();
    const userLoggedIn = UserLoggedIn();

    useEffect(() => {
        const savedCabinets = JSON.parse(localStorage.getItem('savedCabinets'));

        if (savedCabinets && savedCabinets.length > 0) {
            setCabinets(savedCabinets);
            const counters = {};
            savedCabinets.forEach(cabinet => {
                if (cabinet.positionName) {
                    const baseName = cabinet.positionName.replace(/\d+$/, '');
                    const match = cabinet.positionName.match(/\d+$/);
                    const count = match ? parseInt(match[0]) : 0;
                    counters[baseName] = Math.max(counters[baseName] || 0, count);
                }
            });
            setPositionNameCounters(counters);
            setCartItems(cartItems.filter(cartItem =>
                !savedCabinets.some(savedCabinet => savedCabinet.itemId === cartItem.itemId)
            ));
        }
    }, []);

    useEffect(() => {
        if (selectedId && transformerRef.current) {
            const selectedNode = stageRef.current.findOne('#' + selectedId);
            if (selectedNode) {
                transformerRef.current.nodes([selectedNode]);
                transformerRef.current.getLayer().batchDraw();
            }
        } else if (transformerRef.current) {
            transformerRef.current.nodes([]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [selectedId]);

    useEffect(() => {
        if (initialCartItems.length <= 0) {
            setCabinets([]);
            localStorage.removeItem('savedCabinets');
        }
    }, [cartItems]);

    useEffect(() => {
        if (cabinets.length > 0) {
            localStorage.setItem('savedCabinets', JSON.stringify(cabinets));
        }
    }, [cabinets]);

    const getNextPositionName = (positionName) => {
        const originalPositionName = positionName;
        let positionNamePrefix = positionName;
        let positionNameNumber = 1;
        let hasLeadingZeros = false;

        const pattern = /(.*?)(_(\d+)|(\d+))?$/;
        const match = pattern.exec(positionName);

        if (match) {
            positionNamePrefix = match[1];
            if (match[3]) {
                positionNameNumber = parseInt(match[3], 10);
                hasLeadingZeros = match[3].startsWith('0');
            } else if (match[4]) {
                positionNameNumber = parseInt(match[4], 10);
            }
        }

        const relatedCabinets = cabinets.filter(cabinet =>
            cabinet.positionName?.startsWith(positionNamePrefix)
        );

        const positionNameMap = new Set(relatedCabinets.map(cabinet => cabinet.positionName));

        let newPositionName;
        if (match[3]) {
            newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
        } else {
            newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
        }

        while (positionNameMap.has(newPositionName)) {
            positionNameNumber++;
            if (match[3]) {
                newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
            } else {
                newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
            }
        }
        return newPositionName;
    };

    const validateAllDimensions = (index) => {
        const cabinet = cabinets[index];
        const currentValues = inputValues[index] || cabinet;

        let isValid = true;
        let newInputValues = {...currentValues};

        const [minWidth, maxWidth] = cabinet.widthRange.split('-').map(Number);
        if (currentValues.width < minWidth || currentValues.width > maxWidth) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    width: `Must be between ${minWidth}" and ${maxWidth}"`
                }
            }));
            newInputValues.width = cabinet.width;
            isValid = false;
        } else {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    width: null
                }
            }));
        }

        const [minDepth, maxDepth] = cabinet.depthRange.split('-').map(Number);
        if (currentValues.depth < minDepth || currentValues.depth > maxDepth) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    depth: `Must be between ${minDepth}" and ${maxDepth}"`
                }
            }));
            newInputValues.depth = cabinet.depth;
            isValid = false;
        } else {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    depth: null
                }
            }));
        }

        if (!isValid) {
            setInputValues(prev => ({
                ...prev,
                [index]: newInputValues
            }));
        } else {
            handleEditCabinet(index, newInputValues);
        }

        return isValid;
    };

    const handleEditCabinet = (index, newProps) => {
        const groupId = cabinets[index]?.groupId;

        setCabinets((prevCabinets) =>
            prevCabinets.map((cabinet, i) => {
                if (groupId && cabinet.groupId === groupId) {
                    return {
                        ...cabinet,
                        width: newProps.width ?? cabinet.width,
                        depth: newProps.depth ?? cabinet.depth,
                    };
                }
                return i === index ? {...cabinet, ...newProps} : cabinet;
            })
        );
    };

    const validateDimension = (index, name, value) => {
        const cabinet = cabinets[index];
        const range = name === 'width' ? cabinet.widthRange : cabinet.depthRange;
        const [min, max] = range.split('-').map(Number);

        if (value < min || value > max) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    [name]: `Must be between ${min}" and ${max}"`
                }
            }));
            return false;
        }

        setDimensionErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                [name]: null
            }
        }));
        return true;
    };

    const handleInputChange = (index, e) => {
        const {name, value} = e.target;
        const numericValue = parseFloat(value);

        setInputValues(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                [name]: numericValue
            }
        }));

        validateDimension(index, name, numericValue);
    };

    const handleInputFocus = (index, name) => {
        setActiveInput({index, name});
    };

    const handleInputBlur = (index, e) => {
        const {name, value} = e.target;
        const numericValue = parseFloat(value);

        if (validateDimension(index, name, numericValue)) {
            handleEditCabinet(index, {[name]: numericValue});
        } else {
            setInputValues(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    [name]: cabinets[index][name]
                }
            }));
        }
        setActiveInput(null);
    };

    const handleMouseEnter = (index) => {
        setHoveredCabinetIndex(index);
        setMenuVisible(true);
        setInputValues(prev => ({
            ...prev,
            [index]: {
                width: cabinets[index].width,
                depth: cabinets[index].depth
            }
        }));
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            if (!menuVisible) {
                if (hoveredCabinetIndex !== null) {
                    validateAllDimensions(hoveredCabinetIndex);
                }
                setHoveredCabinetIndex(null);
                setDimensionErrors({});
                setInputValues({});
            }
        }, 1000);
    };

    const handleMenuMouseEnter = () => {
        setMenuVisible(true);
    };

    const handleMenuMouseLeave = () => {
        if (hoveredCabinetIndex !== null) {
            validateAllDimensions(hoveredCabinetIndex);
        }
        setMenuVisible(false);
    };

    const handleDimensionChange = (e) => {
        const {name, value} = e.target;
        setDimensions((prev) => ({...prev, [name]: parseFloat(value)}));
    };

    const constrainToLShape = (x, y, width, depth, currentRotation) => {
        const lShapeWidth = dimensions.width / 2;
        const lShapeHeight = dimensions.height / 2;
        let newX = x;
        let newY = y;
        let newRotation = currentRotation;

        let effectiveWidth = currentRotation === 90 ? depth : width;
        let effectiveDepth = currentRotation === 90 ? width : depth;

        if (newX + effectiveWidth > lShapeWidth + 10 && newY < lShapeHeight - 10) {
            newX = lShapeWidth;
            newRotation = 90;
        } else if (newX > dimensions.width) {
            if (newY > lShapeHeight) {
                newX = dimensions.width;
                newRotation = 90;
            }
            if (newY < lShapeHeight) {
                newRotation = 0;
                newX = dimensions.width - effectiveWidth - WALL_THICKNESS;
                newY = lShapeHeight + WALL_THICKNESS;
            }
            if (newY + effectiveDepth > dimensions.height) {
                newY = dimensions.height - effectiveDepth - WALL_THICKNESS;
            }
        } else if ((newX > lShapeWidth) && (Math.abs(newY - effectiveDepth) - Math.abs(lShapeHeight - effectiveDepth)) <= SNAP_DISTANCE) {
            newY = lShapeHeight + WALL_THICKNESS;
            newRotation = 0;
        } else if (newX <= 0 && newY >= 0) {
            newX = WALL_THICKNESS;
            newRotation = -90;
        } else if (newY + effectiveDepth > dimensions.height) {
            newY = dimensions.height - WALL_THICKNESS;
            newRotation = 180;
            if (newX - effectiveWidth <= 0) {
                newX = effectiveWidth + WALL_THICKNESS;
            }
        } else {
            newRotation = 0;
        }
        if ((newY + effectiveDepth) > dimensions.height && (newX) > dimensions.width) {
            newX = dimensions.width - effectiveWidth;
            newY = dimensions.height + effectiveDepth + WALL_THICKNESS;
        }

        newX = Math.max(WALL_THICKNESS, Math.min(newX, dimensions.width - WALL_THICKNESS));
        newY = Math.max(WALL_THICKNESS, Math.min(newY, dimensions.height - WALL_THICKNESS));

        return {x: newX, y: newY, rotation: newRotation};
    };

    const constrainToRectangle = (x, y, width, depth, currentRotation) => {
        let newX = x;
        let newY = y;
        let newRotation = currentRotation;
        let effectiveWidth = currentRotation === 90 ? depth : width;
        let effectiveDepth = currentRotation === 90 ? width : depth;
        console.log('x: ', x)
        console.log('y: ', y)

        if (newX < 0) {
            newRotation = -90;
            newX = WALL_THICKNESS;
            if (newY - effectiveWidth <= 0) {
                newX = 1;
                newY = effectiveWidth + WALL_THICKNESS;
            }
            if (newY >= dimensions.height) {
                newY = dimensions.height - WALL_THICKNESS;
            }
        } else if (newX === 1) {
            if (newY + effectiveDepth >= dimensions.height) {
                newRotation = -180;
                newY = dimensions.height - WALL_THICKNESS;
                newX = newX + effectiveWidth;
            }
        } else if (newX > 1 && newY + effectiveDepth >= dimensions.height) {
            newRotation = -180;
            newY = dimensions.height - WALL_THICKNESS;
            if (newX > dimensions.width) {
                newX = dimensions.width - WALL_THICKNESS;
            }
        } else if (newX + effectiveWidth > dimensions.width + 5) {
            newRotation = 90;
            newX = dimensions.width - WALL_THICKNESS;
            if (newY < 0) {
                newY = WALL_THICKNESS;
            }
        } else if (newY <= 0) {
            newRotation = 0;
            newY = WALL_THICKNESS;
            if (newX + effectiveWidth > dimensions.width) {
                newX = dimensions.width - effectiveWidth;
                newY = WALL_THICKNESS;
            }
        }
        // else newRotation = 0;

        return {x: newX, y: newY, rotation: newRotation};
    };

    const handleDragMove = (index, e) => {
        let newX = e.target.x();
        let newY = e.target.y();
        const cabinetWidth = cabinets[index].width;
        const cabinetDepth = cabinets[index].depth;
        let newRotation = e.target.rotation();

        if (roomShape === 'l-shaped') {
            const constrainedPosition = constrainToLShape(newX, newY, cabinetWidth, cabinetDepth, newRotation);
            newX = constrainedPosition.x;
            newY = constrainedPosition.y;
            newRotation = constrainedPosition.rotation;
        } else if (roomShape === 'rectangular') {
            const constrainedPosition = constrainToRectangle(newX, newY, cabinetWidth, cabinetDepth, newRotation);
            newX = constrainedPosition.x;
            newY = constrainedPosition.y;
            newRotation = constrainedPosition.rotation;
        } else {
            newX = Math.max(WALL_THICKNESS, Math.min(newX, dimensions.width - cabinetWidth - WALL_THICKNESS));
            newY = Math.max(WALL_THICKNESS, Math.min(newY, dimensions.height - cabinetDepth - WALL_THICKNESS));
        }

        cabinets.forEach((cabinet, i) => {
            if (i !== index) {
                if (Math.abs(newX - (parseFloat(cabinet?.x) + parseFloat(cabinet?.width))) <= SNAP_DISTANCE) {
                    newX = parseFloat(cabinet?.x) + parseFloat(cabinet.width);
                } else if (Math.abs(newX + cabinetWidth - parseFloat(cabinet?.x)) <= SNAP_DISTANCE) {
                    newX = parseFloat(cabinet?.x) - cabinetWidth;
                }
                if (Math.abs(newY - (parseFloat(cabinet?.y) + parseFloat(cabinet?.depth))) <= SNAP_DISTANCE) {
                    newY = parseFloat(cabinet?.y) + parseFloat(cabinet?.depth);
                } else if (Math.abs(newY + cabinetDepth - parseFloat(cabinet?.y)) <= SNAP_DISTANCE) {
                    newY = parseFloat(cabinet.y) - cabinetDepth;
                }
            }
        });

        e.target.x(newX);
        e.target.y(newY);
        e.target.rotation(newRotation);

        const updatedCabinets = cabinets.map((cabinet, i) =>
            i === index ? {...cabinet, x: newX, y: newY, rotation: newRotation} : cabinet
        );
        setCabinets(updatedCabinets);
    };

    const handleDragEnd = (index, e) => {
        const newX = e.target.x();
        const newY = e.target.y();
        handleEditCabinet(index, {x: newX, y: newY});
    };

    const handleCartItemDragStart = (e, item) => {
        if (item.quantity > 0) {
            dragItemRef.current = item;
        }
    };

    const handleStageDragOver = (e) => {
        e.preventDefault();
    };

    const handleStageDrop = (e) => {
        e.preventDefault();
        let isCopy = false;
        const stage = stageRef.current;
        if (stage && dragItemRef.current) {
            stage.setPointersPositions(e);
            const pointerPosition = stage.getPointerPosition();
            const newPositionName = dragItemRef.current.positionName ?
                getNextPositionName(dragItemRef.current.positionName) :
                dragItemRef.current.positionName;

            const groupId = dragItemRef.current?.groupId;

            if (groupId !== null && cabinets.length > 0) {
                const foundCabinet = cabinets.find((cabinet) => cabinet?.groupId === groupId);
                if (foundCabinet) {
                    dragItemRef.current.width = foundCabinet.width;
                    dragItemRef.current.depth = foundCabinet.depth;
                    isCopy = true;
                } else {
                    isCopy = false;
                }
            }

            const newCabinet = {
                ...dragItemRef.current,
                x: pointerPosition.x,
                y: pointerPosition.y,
                rotation: 0,
                id: Date.now(),
                positionName: newPositionName,
                isCopy: isCopy,
            }

            setCabinets([...cabinets, newCabinet]);

            const updatedCartItems = cartItems.map(item =>
                item.itemId === dragItemRef.current.itemId
                    ? {...item, quantity: item.quantity - 1}
                    : item
            ).filter(item => item.quantity > 0);

            setCartItems(updatedCartItems);
            dragItemRef.current = null;
        }
    };

    const handleReturnToPanelClick = (index) => {
        const cabinetToReturn = cabinets[index];
        const existingCartItem = cartItems.find(item => item.itemId === cabinetToReturn.itemId);

        if (existingCartItem) {
            setCartItems(cartItems.map(item =>
                item.itemId === cabinetToReturn.itemId
                    ? {...item, quantity: item.quantity + 1}
                    : item
            ));
        } else {
            setCartItems([...cartItems, {...cabinetToReturn, quantity: 1}]);
        }

        setCabinets(cabinets.filter((_, i) => i !== index));
        setHoveredCabinetIndex(null);
        setDimensionErrors({});
        setInputValues({});
        setMenuVisible(false);
    };

    const handleClearLayout = () => {
        setCartItems(initialCartItems);
        setCabinets([]);
        setPositionNameCounters({});
        localStorage.removeItem('savedCabinets');
        setDimensionErrors({});
        setInputValues({});
        setMenuVisible(false);
    };

    const handleSaveChanges = () => {
        dispatch(updateItemsInCart(cabinets.filter((cabinet) => cabinet?.isCopy !== true)))
        dispatch(updateCabinetsFromDrawingTool(cabinets))
    };

    const checkDeselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        const layer = e.target.getLayer();

        const clickedOnBackground =
            layer && (layer.findOne('Rect') === e.target || layer.findOne('Line') === e.target);

        if (clickedOnEmpty || clickedOnBackground) {
            setSelectedId(null);
        }
    };

    const handleTransformEnd = (cabinet) => {
        if (selectedId && transformerRef.current) {
            const selectedNode = stageRef.current.findOne('#' + selectedId);
            if (selectedNode) {
                const selectedCabinet = transformerRef.current.nodes([selectedNode]);
                cabinet.rotation = parseFloat(selectedCabinet.rotation().toFixed(2));
                // console.log('cabinet: ', cabinet)
            }
        }
    };

    const handleGroupClick = (id) => {
        setSelectedId(id);
        const foundCabinet = cabinets.find((cabinet) => parseInt(id) === cabinet.id);
        console.log('cabinets: ', foundCabinet)
    };

    const handleExportToPDF = () => {
        const dataURL = stageRef.current.toDataURL({pixelRatio: 2});
        const pdf = new jsPDF({
            orientation: dimensions.width > dimensions.height ? 'l' : 'p',
            unit: 'px',
            format: [dimensions.width, dimensions.height]
        });

        pdf.addImage(dataURL, 'PNG', 0, 0, dimensions.width, dimensions.height);
        pdf.save('layout.pdf');
    };

    return (
        <>
            {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
            <div className="drawing-tool-container container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="drawing-tool-controls card p-4 mb-4">
                            <h2 className="drawing-tool-title mb-4">Layout Designer</h2>
                            <div className="mb-3">
                                <label htmlFor="room-shape" className="form-label">Room Shape:</label>
                                <select
                                    id="room-shape"
                                    value={roomShape}
                                    onChange={(e) => setRoomShape(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="rectangular">Rectangular</option>
                                    <option value="l-shaped">L Shaped</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="room-width" className="form-label">Room Width (inches):</label>
                                <input
                                    type="number"
                                    id="room-width"
                                    name="width"
                                    value={dimensions.width}
                                    onChange={handleDimensionChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="room-height" className="form-label">Room Height (inches):</label>
                                <input
                                    type="number"
                                    id="room-height"
                                    name="height"
                                    value={dimensions.height}
                                    onChange={handleDimensionChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="cart-inventory-items-card card p-4 mt-4">
                            <h3 className="cart-inventory-items-title mb-4">Cart Items</h3>
                            <div className="cart-inventory-items-grid">
                                {cartItems.map((item) => (
                                    <div
                                        key={item.itemId}
                                        className="cart-inventory-item"
                                        draggable
                                        onDragStart={(e) => handleCartItemDragStart(e, item)}
                                    >
                                        <div
                                            className="cart-inventory-item-rectangle"
                                            style={{
                                                width: '100%',
                                                height: `100%`,
                                                backgroundColor: '#e0e0e0',
                                                border: '1px solid #555',
                                            }}
                                        >
                                            <span className="cart-inventory-item-text">
                                                {item?.positionName ? item.positionName.toUpperCase() : `${item.width}" x ${item.depth}"`}
                                            </span>
                                            <span className="cart-inventory-item-quantity">
                                                ({item.quantity})
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <button
                            className="btn btn-dark save-chngs-btn"
                            onClick={() => handleClearLayout()}
                        >Clear Layout
                        </button>
                        <button
                            className="btn btn-dark save-chngs-btn"
                            onClick={() => handleSaveChanges()}>
                            Save Changes
                        </button>
                        {/* Export to PDF Button */}
                        <button
                            className="btn btn-dark save-chngs-btn"
                            onClick={handleExportToPDF}>
                            Export to PDF
                        </button>
                        <div
                            className="drawing-tool-stage-wrapper"
                            onDragOver={handleStageDragOver}
                            onDrop={handleStageDrop}
                        >
                            <Stage
                                width={dimensions.width}
                                height={dimensions.height}
                                ref={stageRef}
                                className="drawing-tool-stage"
                                onClick={checkDeselect}
                            >
                                <Layer>
                                    {roomShape === 'rectangular' && (
                                        <Rect
                                            x={0}
                                            y={0}
                                            width={dimensions.width}
                                            height={dimensions.height}
                                            fill="white"
                                            stroke="black"
                                            strokeWidth={WALL_THICKNESS}
                                        />
                                    )}
                                    {roomShape === 'l-shaped' && (
                                        <Line
                                            points={[
                                                0, 0,
                                                dimensions.width / 2, 0,
                                                dimensions.width / 2, dimensions.height / 2,
                                                dimensions.width, dimensions.height / 2,
                                                dimensions.width, dimensions.height,
                                                0, dimensions.height,
                                                0, 0,
                                            ]}
                                            fill="white"
                                            stroke="black"
                                            strokeWidth={WALL_THICKNESS}
                                            closed
                                        />
                                    )}
                                    {cabinets.map((cabinet, index) => (
                                        <Group
                                            key={cabinet.id || cabinet.itemId}
                                            id={cabinet.id.toString()}
                                            x={cabinet.x}
                                            y={cabinet.y}
                                            rotation={cabinet.rotation || 0}
                                            draggable
                                            onClick={() => handleGroupClick(cabinet.id.toString())}
                                            onDragMove={(e) => handleDragMove(index, e)}
                                            onDragEnd={(e) => handleDragEnd(index, e)}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}
                                            onTransformEnd={() => handleTransformEnd(cabinet)}
                                        >
                                            <Rect
                                                width={parseFloat(cabinet?.width)}
                                                height={parseFloat(cabinet?.depth)}
                                                fill="#e0e0e0"
                                                stroke="#555"
                                                strokeWidth={1}
                                            />
                                            <Text
                                                width={parseFloat(cabinet?.width) > 40 ? parseFloat(cabinet?.width) : 40}
                                                height={52}
                                                text={cabinet?.positionName}
                                                fontSize={8}
                                                fill="black"
                                                fontStyle="bold"
                                                align="center"
                                                verticalAlign="bottom"
                                                padding={10}
                                                y={parseFloat(cabinet?.depth) > 40 ? parseFloat(cabinet?.depth) - 16 : parseFloat(cabinet?.depth)}
                                            />
                                        </Group>
                                    ))}
                                    {selectedId && (
                                        <Transformer
                                            ref={transformerRef}
                                            rotationSnaps={[0, 90, 180, 270]}
                                            boundBoxFunc={(oldBox, newBox) => {
                                                return newBox;
                                            }}
                                            resizeEnabled={false}
                                            rotation={90}
                                        />
                                    )}
                                </Layer>
                            </Stage>

                            {hoveredCabinetIndex !== null && menuVisible && (
                                <div
                                    className="drawing-tool-cabinet-menu"
                                    style={{
                                        position: 'absolute',
                                        left: `${
                                            cabinets[hoveredCabinetIndex]?.x +
                                            stageRef.current.container().offsetLeft +
                                            parseFloat(cabinets[hoveredCabinetIndex]?.width) +
                                            10
                                        }px`,
                                        top: `${
                                            cabinets[hoveredCabinetIndex]?.y +
                                            stageRef.current.container().offsetTop + parseFloat(cabinets[hoveredCabinetIndex]?.depth)
                                        }px`,
                                        zIndex: 100,
                                        padding: '1rem',
                                    }}
                                    onMouseEnter={handleMenuMouseEnter}
                                    onMouseLeave={handleMenuMouseLeave}
                                >
                                    <div className="cabinet-menu-inputs-container">
                                        <div className="cabinet-dimension-group">
                                            <label htmlFor={`width-${hoveredCabinetIndex}`}
                                                   className="cabinet-dimension-label">
                                                Width:
                                            </label>
                                            <input
                                                type="number"
                                                id={`width-${hoveredCabinetIndex}`}
                                                className={`form-control form-control-sm cabinet-dimension-input ${
                                                    dimensionErrors[hoveredCabinetIndex]?.width ? 'is-invalid' : ''
                                                }`}
                                                name="width"
                                                value={inputValues[hoveredCabinetIndex]?.width ?? cabinets[hoveredCabinetIndex]?.width}
                                                onChange={(e) => handleInputChange(hoveredCabinetIndex, e)}
                                                onFocus={() => handleInputFocus(hoveredCabinetIndex, 'width')}
                                                onBlur={(e) => handleInputBlur(hoveredCabinetIndex, e)}
                                                step="0.001"
                                            />
                                            {dimensionErrors[hoveredCabinetIndex]?.width && (
                                                <div className="cabinet-dimension-error">
                                                    {dimensionErrors[hoveredCabinetIndex]?.width}
                                                </div>
                                            )}
                                        </div>
                                        <div className="cabinet-dimension-group">
                                            <label htmlFor={`depth-${hoveredCabinetIndex}`}
                                                   className="cabinet-dimension-label">
                                                Depth:
                                            </label>
                                            <input
                                                type="number"
                                                id={`depth-${hoveredCabinetIndex}`}
                                                className={`form-control form-control-sm cabinet-dimension-input ${
                                                    dimensionErrors[hoveredCabinetIndex]?.depth ? 'is-invalid' : ''
                                                }`}
                                                name="depth"
                                                value={inputValues[hoveredCabinetIndex]?.depth ?? cabinets[hoveredCabinetIndex]?.depth}
                                                onChange={(e) => handleInputChange(hoveredCabinetIndex, e)}
                                                onFocus={() => handleInputFocus(hoveredCabinetIndex, 'depth')}
                                                onBlur={(e) => handleInputBlur(hoveredCabinetIndex, e)}
                                                step="0.001"
                                            />
                                            {dimensionErrors[hoveredCabinetIndex]?.depth && (
                                                <div className="cabinet-dimension-error">
                                                    {dimensionErrors[hoveredCabinetIndex]?.depth}
                                                </div>
                                            )}
                                        </div>
                                        <div className="cabinet-button-group">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-${hoveredCabinetIndex}`}>Return to
                                                    Panel</Tooltip>}
                                            >
                                                <button
                                                    className="btn btn-outline-secondary btn-sm cabinet-return-button"
                                                    onClick={() => handleReturnToPanelClick(hoveredCabinetIndex)}
                                                >
                                                    <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DrawingTool;
