// DataService.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL

const DataService = {
    getAllMaterials: async () => {
        try {
            // const response = await axios.get('http://localhost:8080/getAllMaterials');
            const response = await axios.get(`${baseURL}/getAllMaterials`);
            return response.data;
        } catch (error) {
            console.error('Error fetching materials:', error);
            return [];
        }
    },

    getAllEdgebanding: async () => {
        try {
            // const response = await axios.get('http://localhost:8080/getAllMaterials');
            const response = await axios.get(`${baseURL}/api/cabinet-features/getAllEdgebanding`);
            return response.data;
        } catch (error) {
            console.error('Error fetching materials:', error);
            return [];
        }
    },

};

export default DataService;
