import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

function OrderService(order, token, cabinetsWithCoordinates) {
    return axios({
        method: 'post',
        url: `${baseURL}/order`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            order,
            cabinetsWithCoordinates

        }
    })
}

export const fetchArticlesByOrderId = async (orderId, token) => {
    try {
        return await axios.get(`${baseURL}/user/customer-portal/${orderId}/edit-order`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    } catch (error) {
        console.error('Error fetching articles by order ID:', error);
        throw error;
    }
};

export const submitEditOrder = async (order, token) => {
    try {
        return axios({
            method: 'post',
            url: `${baseURL}/user/customer-portal/${order.orderId}/edit-order/submit`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: order
        })
    } catch (e) {
        return `Error editing order: ${e}`
    }
}

function saveOrderForLater(order, token) {
    return axios({
        method: 'post',
        url: `${baseURL}/saveOrderForLater`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: order
    })
}

function getAllPastOrders(email) {
    return axios({
        method: 'post',
        url: `${baseURL}/getAllOrders`,
        data: {email: email},
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${user.accessToken}`
        }
    })
}

function getAllPastOrdersByEmail(email, token) {
    return axios({
        method: 'post',
        url: `${baseURL}/user/customer-portal/getAllOrders`,
        data: {email: email},
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

function getAllSavedOrders(email, token) {
    return axios({
        method: 'post',
        url: `${baseURL}/getAllSavedOrders`,
        data: {email: email},
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}`
        }
    })
}

function deleteSavedOrder(tempOrderId, token) {
    return axios({
        method: 'get',
        url: `${baseURL}/user/customer-portal/${tempOrderId}/deleteSavedOrder`,
        data: {},
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}`
        }
    })
}

function setOrderVariables(orderVariables, user) {
    return axios({
        method: 'post',
        url: `${baseURL}/saveOrderVariables`,
        data: orderVariables,
        headers: {
            // 'Authorization': `Bearer ${user.accessToken}`
        }
    })
}

export {
    OrderService,
    getAllPastOrders,
    setOrderVariables,
    saveOrderForLater,
    getAllSavedOrders,
    getAllPastOrdersByEmail,
    deleteSavedOrder
};

export default OrderService