// import {createSlice, current, nanoid} from '@reduxjs/toolkit';
//
// const initialState = {
//     cart: [],
//     totalQuantity: 0,
//     orderNumber: nanoid(),
//     totalPrice: 0.0,
//     uniqueItemId: nanoid(),
//     selectedItems: [],
//     foundItem: {},
//     paymentSuccess: false,
//     isRevisedOrder: false,
//     revisedOrderInfo: {},
// };
//
// const cartSlice = createSlice({
//     name: 'cart',
//     initialState,
//     reducers: {
//         addToCart: (state, action) => {
//             if (action.payload) {
//                 if (action.payload.item) {
//                     const itemWithLegLevelers = {
//                         ...action.payload.item,
//                         includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
//                     };
//                     state.cart.push(itemWithLegLevelers);
//                     state.totalQuantity += parseInt(action.payload.quantity);
//                 } else {
//                     const item = {
//                         itemId: action?.payload.id,
//                         backPanel: action.payload?.backPanel || 'Inset',
//                         serialNumber: action.payload?.serialNumber || 'test',
//                         drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
//                         gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
//                         gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
//                         gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
//                         gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
//                         gapCenter: (1 / 8).toFixed(3),
//                         jointControl: action.payload?.jointControl,
//                         jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
//                         heightRange: action.payload?.heightRange,
//                         widthRange: action.payload?.widthRange,
//                         depthRange: action.payload?.depthRange,
//                         frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
//                         caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || `3/4" Pre-finished Maple`,
//                         quantity: action.payload.quantity || 1,
//                         // hingeSide: action.payload?.doors === '0' ? false : action.payload?.hingeSide || 'Left',
//                         height: action.payload?.height
//                             ? action.payload.height
//                             : action.payload?.heightRange.split('-')[0],
//                         width: action.payload?.width
//                             ? action.payload.width
//                             : action.payload?.widthRange.split('-')[0],
//                         depth: action.payload?.depth
//                             ? action.payload.depth
//                             : action.payload?.depthRange.split('-')[0],
//                         positionName: action.payload?.positionName,
//                         caseEdge: action.payload?.caseEdge,
//                         frontEdge: action.payload?.frontEdge,
//                         edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edgeBandingTop: action.payload?.edgeBandingTop || 'false',
//                         edgeBandingBottom: action.payload?.edgeBandingBottom,
//                         edgeBandingRight: action.payload?.edgeBandingRight,
//                         edgeBandingLeft: action.payload?.edgeBandingLeft,
//                         edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
//                         description: action.payload?.description,
//                         drawers: action.payload?.drawers,
//                         doors: action.payload?.doors,
//                         topDrwrHeight: action.payload?.topDrwrHeight || '1',
//                         topDrwrHeightValue: action.payload?.topDrwrHeightValue || '6',
//                         adjShelves: action.payload?.adjShelves,
//                         numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
//                         includeLegLevelers: action.payload?.includeLegLevelers || 'false',
//                         bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
//                         hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
//                         excludeFronts: action.payload?.excludeFronts || false,
//                         matBack: action.payload?.matBack,
//                         legLevelers: action.payload?.legLevelers,
//                         gapControl: action.payload?.gapControl,
//                         filterTags: action.payload?.filterTags,
//                         globalSettingsApplied: action.payload?.globalSettingsApplied || false,
//                         leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
//                         rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
//                     };
//                     state.cart.push(item);
//                     state.totalQuantity += parseInt(action.payload.quantity);
//                 }
//             }
//         },
//         reAddToCart: (state, action) => {
//             state.cart.push({
//                 ...action.payload,
//                 itemId: action.payload.itemId,
//                 quantity: Number(action.payload.quantity)
//             });
//             state.totalQuantity += parseInt(action.payload.quantity);
//         },
//         incrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 state.cart[itemIndex].quantity += 1;
//                 state.totalQuantity += 1;
//             }
//         },
//         decrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
//                 state.cart[itemIndex].quantity -= 1;
//                 state.totalQuantity -= 1;
//             }
//         },
//         removeItem: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 state.totalQuantity -= parseInt(state.cart[itemIndex].quantity);
//                 state.cart.splice(itemIndex, 1);
//             }
//
//             if (state.totalQuantity < 0) {
//                 state.totalQuantity = 0;
//             }
//             if (state.totalQuantity === 0) {
//                 state.isRevisedOrder = false;
//                 state.revisedOrderInfo = {}
//             }
//         },
//         emptyCart: (state) => {
//             state.cart = [];
//             state.totalQuantity = 0;
//             state.orderNumber = nanoid();
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//         },
//         updateItemsInCart: (state, action) => {
//             const updatedItems = action.payload;
//
//             updatedItems.forEach((updatedItem) => {
//                 const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);
//
//                 if (itemIndex >= 0) {
//                     state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
//                 }
//             });
//         },
//         paymentSucceeded: (state, action) => {
//             state.paymentSuccess = action.payload;
//         },
//         findItemById: (state, action) => {
//             const itemIndex = state.cart.findIndex(item => item.itemId === action.payload)
//             state.foundItem = current(state.cart[itemIndex]);
//
//         },
//         setIsRevisedOrder: (state, action) => {
//             state.isRevisedOrder = action.payload;
//         },
//         setOrderInfo: (state, action) => {
//             state.revisedOrderInfo = action.payload;
//         },
//         clearRevisedOrder: (state, action) => {
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {}
//         }
//     },
//
// });
//
// export const selectCart = (state) => state.cart;
// // export const selectFoundItem = (state) => state.cart.foundItem;
//
// export const {
//     addToCart,
//     reAddToCart,
//     incrementQuantity,
//     decrementQuantity,
//     removeItem,
//     emptyCart,
//     updateItemsInCart,
//     paymentSucceeded,
//     findItemById,
//     setIsRevisedOrder,
//     setOrderInfo,
//     clearRevisedOrder
// } = cartSlice.actions;
//
// export default cartSlice.reducer;


// import {createSlice, current, nanoid} from '@reduxjs/toolkit';
//
// const initialState = {
//     cart: [],
//     totalQuantity: 0,
//     orderNumber: nanoid(),
//     totalPrice: 0.0,
//     uniqueItemId: nanoid(),
//     selectedItems: [],
//     foundItem: {},
//     paymentSuccess: false,
//     isRevisedOrder: false,
//     revisedOrderInfo: {},
//     positionCounter: 1, // Position counter starts from 1
//     reservedPositionNames: [],
// };
//
// const cartSlice = createSlice({
//     name: 'cart',
//     initialState,
//     reducers: {
//         addToCart: (state, action) => {
//             if (action.payload) {
//                 if (action.payload.item) {
//                     // If there is an item payload, add it to the cart with leg leveler option
//                     const itemWithLegLevelers = {
//                         ...action.payload.item,
//                         includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
//                     };
//                     state.cart.push(itemWithLegLevelers);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 } else {
//                     // Function to get the next available position name
//                     const getNextPositionName = (state) => {
//                         // Initialize positionCounter if it doesn't exist
//                         if (state.positionCounter === undefined) {
//                             state.positionCounter = 1;
//                         }
//
//                         // Extract only numeric position names from the cart items
//                         const numericPositionNames = state.cart
//                             .map(item => parseInt(item.positionName, 10))
//                             .filter(position => !isNaN(position));
//
//                         // Calculate the next position based on the highest numeric position name in the cart or default to 1
//                         const nextPosition = numericPositionNames.length > 0
//                             ? Math.max(...numericPositionNames) + 1
//                             : state.positionCounter;
//
//                         state.positionCounter = nextPosition + 1; // Update positionCounter
//
//                         const checkPosition = nextPosition.toString().padStart(3, '0');
//
//                         if (state.cart.filter((item) => item?.positionName === checkPosition)) {
//                             state.positionCounter += 1;
//                         }
//
//                         // Return the next position name as a string with leading zeros
//                         return nextPosition.toString().padStart(3, '0');
//                     };
//
//                     // Generate the position name for the new item
//                     const positionName = getNextPositionName(state);
//
//                     const item = {
//                         itemId: action?.payload.id,
//                         backPanel: action.payload?.backPanel || 'Inset',
//                         serialNumber: action.payload?.serialNumber || 'test',
//                         drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
//                         gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
//                         gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
//                         gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
//                         gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
//                         gapCenter: (1 / 8).toFixed(3),
//                         jointControl: action.payload?.jointControl,
//                         jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
//                         heightRange: action.payload?.heightRange,
//                         widthRange: action.payload?.widthRange,
//                         depthRange: action.payload?.depthRange,
//                         frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
//                         caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || `3/4" Pre-finished Maple`,
//                         quantity: action.payload.quantity || 1,
//                         height: action.payload?.height
//                             ? action.payload.height
//                             : action.payload?.heightRange?.split('-')[0],
//                         width: action.payload?.width
//                             ? action.payload.width
//                             : action.payload?.widthRange?.split('-')[0],
//                         depth: action.payload?.depth
//                             ? action.payload.depth
//                             : action.payload?.depthRange?.split('-')[0],
//                         positionName: action.payload?.positionName || positionName, // Assign unique positionName
//                         caseEdge: action.payload?.caseEdge,
//                         frontEdge: action.payload?.frontEdge,
//                         edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edgeBandingTop: action.payload?.edgeBandingTop || 'false',
//                         edgeBandingBottom: action.payload?.edgeBandingBottom,
//                         edgeBandingRight: action.payload?.edgeBandingRight,
//                         edgeBandingLeft: action.payload?.edgeBandingLeft,
//                         edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
//                         description: action.payload?.description,
//                         drawers: action.payload?.drawers,
//                         doors: action.payload?.doors,
//                         topDrwrHeight: action.payload?.topDrwrHeight || '1',
//                         // topDrwrHeightValue: action.payload?.topDrwrHeightValue || '6',
//                         topDrwrHeightValue: action.payload?.topDrwrHeight === '1' ? action.payload?.topDrwrHeightValue : '6',
//                         adjShelves: action.payload?.adjShelves,
//                         numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
//                         includeLegLevelers: action.payload?.includeLegLevelers || 'false',
//                         bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
//                         hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
//                         excludeFronts: action.payload?.excludeFronts || false,
//                         matBack: action.payload?.matBack || action.payload?.caseMaterial,
//                         legLevelers: action.payload?.legLevelers,
//                         gapControl: action.payload?.gapControl,
//                         filterTags: action.payload?.filterTags,
//                         globalSettingsApplied: action.payload?.globalSettingsApplied || false,
//                         leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
//                         rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
//                         groupId: action.payload?.quantity > 1 ? nanoid() : null
//                     };
//
//                     state.cart.push(item);
//                     state.totalQuantity += parseInt(action.payload.quantity);
//                 }
//             }
//         },
//
//         reAddToCart: (state, action) => {
//             state.cart.push({
//                 ...action.payload,
//                 itemId: action.payload.itemId,
//                 quantity: Number(action.payload.quantity),
//             });
//             state.totalQuantity += parseInt(action.payload.quantity);
//         },
//         incrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 state.cart[itemIndex].quantity += 1;
//                 state.totalQuantity += 1;
//             }
//             if (state.cart[itemIndex]?.quantity > 1 && state.cart[itemIndex]?.groupId === null) {
//                 state.cart[itemIndex].groupId = nanoid();
//             }
//         },
//         decrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
//                 state.cart[itemIndex].quantity -= 1;
//                 state.totalQuantity -= 1;
//             }
//
//             if (state.cart[itemIndex].quantity === 1)
//                 state.cart[itemIndex].groupId = null;
//
//         },
//         removeItem: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 state.totalQuantity -= parseInt(state.cart[itemIndex].quantity);
//                 state.cart.splice(itemIndex, 1);
//             }
//
//             if (state.totalQuantity < 0) {
//                 state.totalQuantity = 0;
//             }
//             if (state.totalQuantity === 0) {
//                 state.isRevisedOrder = false;
//                 state.revisedOrderInfo = {};
//             }
//         },
//         emptyCart: (state) => {
//             state.cart = [];
//             state.totalQuantity = 0;
//             state.orderNumber = nanoid();
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//             state.positionCounter = 1;
//             state.reservedPositionNames.length = 0;
//         },
//         updateItemsInCart: (state, action) => {
//             const updatedItems = action.payload;
//
//             updatedItems.forEach((updatedItem) => {
//                 const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);
//
//                 if (itemIndex >= 0) {
//                     state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
//                 }
//             });
//         },
//         paymentSucceeded: (state, action) => {
//             state.paymentSuccess = action.payload;
//         },
//         findItemById: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload);
//             state.foundItem = current(state.cart[itemIndex]);
//         },
//         setIsRevisedOrder: (state, action) => {
//             state.isRevisedOrder = action.payload;
//         },
//         setOrderInfo: (state, action) => {
//             state.revisedOrderInfo = action.payload;
//         },
//         clearRevisedOrder: (state) => {
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//         },
//     },
// });
//
// export const selectCart = (state) => state.cart;
//
// export const {
//     addToCart,
//     reAddToCart,
//     incrementQuantity,
//     decrementQuantity,
//     removeItem,
//     emptyCart,
//     updateItemsInCart,
//     paymentSucceeded,
//     findItemById,
//     setIsRevisedOrder,
//     setOrderInfo,
//     clearRevisedOrder,
// } = cartSlice.actions;
//
// export default cartSlice.reducer;


// import {createSlice, nanoid} from '@reduxjs/toolkit';
//
// const initialState = {
//     cart: [],
//     totalQuantity: 0,
//     orderNumber: nanoid(),
//     totalPrice: 0.0,
//     uniqueItemId: nanoid(),
//     selectedItems: [],
//     foundItem: {},
//     paymentSuccess: false,
//     isRevisedOrder: false,
//     revisedOrderInfo: {},
//     positionCounter: 1, // Position counter starts from 1
//     reservedPositionNames: [], // Array to hold unique position names
// };
//
// // Helper function to generate the next unique position name
// const getNextUniquePositionName = (state) => {
//     let positionName;
//     do {
//         positionName = state.positionCounter.toString().padStart(3, '0');
//         state.positionCounter += 1;
//     } while (state.reservedPositionNames.includes(positionName));
//
//     // Add the unique position name to the array immutably
//     state.reservedPositionNames = [...state.reservedPositionNames, positionName];
//     return positionName;
// };
//
// const cartSlice = createSlice({
//     name: 'cart',
//     initialState,
//     reducers: {
//         addToCart: (state, action) => {
//             if (action.payload) {
//                 if (action.payload.item) {
//                     const itemWithLegLevelers = {
//                         ...action.payload.item,
//                         includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
//                     };
//                     state.cart.push(itemWithLegLevelers);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 } else {
//                     const positionName = action.payload.positionName || getNextUniquePositionName(state);
//
//                     const item = {
//                         itemId: action?.payload.id,
//                         backPanel: action.payload?.backPanel || 'Inset',
//                         serialNumber: action.payload?.serialNumber || 'test',
//                         drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
//                         gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
//                         gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
//                         gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
//                         gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
//                         gapCenter: (1 / 8).toFixed(3),
//                         jointControl: action.payload?.jointControl,
//                         jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
//                         heightRange: action.payload?.heightRange,
//                         widthRange: action.payload?.widthRange,
//                         depthRange: action.payload?.depthRange,
//                         frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
//                         caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || `3/4" Pre-finished Maple`,
//                         quantity: action.payload.quantity || 1,
//                         height: action.payload?.height || action.payload?.heightRange?.split('-')[0],
//                         width: action.payload?.width || action.payload?.widthRange?.split('-')[0],
//                         depth: action.payload?.depth || action.payload?.depthRange?.split('-')[0],
//                         positionName,
//                         caseEdge: action.payload?.caseEdge,
//                         frontEdge: action.payload?.frontEdge,
//                         edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edgeBandingTop: action.payload?.edgeBandingTop || 'false',
//                         edgeBandingBottom: action.payload?.edgeBandingBottom,
//                         edgeBandingRight: action.payload?.edgeBandingRight,
//                         edgeBandingLeft: action.payload?.edgeBandingLeft,
//                         edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
//                         description: action.payload?.description,
//                         drawers: action.payload?.drawers,
//                         doors: action.payload?.doors,
//                         topDrwrHeight: action.payload?.topDrwrHeight || '1',
//                         topDrwrHeightValue: action.payload?.topDrwrHeight === '1' ? action.payload?.topDrwrHeightValue : '6',
//                         adjShelves: action.payload?.adjShelves,
//                         numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
//                         includeLegLevelers: action.payload?.includeLegLevelers || 'false',
//                         bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
//                         hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
//                         excludeFronts: action.payload?.excludeFronts || false,
//                         matBack: action.payload?.matBack,
//                         legLevelers: action.payload?.legLevelers,
//                         gapControl: action.payload?.gapControl,
//                         filterTags: action.payload?.filterTags,
//                         globalSettingsApplied: action.payload?.globalSettingsApplied || false,
//                         leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
//                         rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
//                         groupId: action.payload?.quantity > 1 ? nanoid() : null,
//                     };
//
//                     state.cart.push(item);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 }
//             }
//         },
//
//         reAddToCart: (state, action) => {
//             const positionName = action.payload.positionName || getNextUniquePositionName(state);
//             state.cart.push({
//                 ...action.payload,
//                 positionName,
//                 itemId: action.payload.itemId,
//                 quantity: Number(action.payload.quantity),
//             });
//             state.totalQuantity += parseInt(action.payload.quantity, 10);
//         },
//
//         incrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 const item = state.cart[itemIndex];
//                 item.quantity += 1;
//                 state.totalQuantity += 1;
//
//                 // Add a new position name to reservedPositionNames without modifying item's positionName
//                 const newPositionName = getNextUniquePositionName(state);
//                 state.reservedPositionNames = [...state.reservedPositionNames, newPositionName];
//                 console.log('reservedPositionNames: ', state.reservedPositionNames);
//
//                 if (item.quantity > 1 && item.groupId === null) {
//                     item.groupId = nanoid();
//                 }
//             }
//         },
//
//         decrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
//                 const item = state.cart[itemIndex];
//                 item.quantity -= 1;
//                 state.totalQuantity -= 1;
//
//                 // Remove the last added position name from reservedPositionNames
//                 state.reservedPositionNames = state.reservedPositionNames.slice(0, -1);
//             }
//
//             if (state.cart[itemIndex].quantity === 1) {
//                 state.cart[itemIndex].groupId = null;
//             }
//         },
//
//         removeItem: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 const removedItem = state.cart[itemIndex];
//                 state.totalQuantity -= parseInt(removedItem.quantity, 10);
//
//                 // Remove as many position names as the item's quantity from reservedPositionNames
//                 state.reservedPositionNames = state.reservedPositionNames.slice(0, -removedItem.quantity);
//
//                 state.cart.splice(itemIndex, 1);
//             }
//
//             if (state.totalQuantity < 0) {
//                 state.totalQuantity = 0;
//             }
//             if (state.totalQuantity === 0) {
//                 state.isRevisedOrder = false;
//                 state.revisedOrderInfo = {};
//             }
//         },
//
//         emptyCart: (state) => {
//             state.cart = [];
//             state.totalQuantity = 0;
//             state.orderNumber = nanoid();
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//             state.positionCounter = 1;
//             state.reservedPositionNames = []; // Clear reserved position names
//         },
//
//         updateItemsInCart: (state, action) => {
//             const updatedItems = action.payload;
//
//             updatedItems.forEach((updatedItem) => {
//                 const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);
//
//                 if (itemIndex >= 0) {
//                     state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
//                 }
//             });
//         },
//
//         paymentSucceeded: (state, action) => {
//             state.paymentSuccess = action.payload;
//         },
//
//         findItemById: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload);
//             state.foundItem = state.cart[itemIndex] ? {...state.cart[itemIndex]} : {};
//         },
//
//         setIsRevisedOrder: (state, action) => {
//             state.isRevisedOrder = action.payload;
//         },
//
//         setOrderInfo: (state, action) => {
//             state.revisedOrderInfo = action.payload;
//         },
//
//         clearRevisedOrder: (state) => {
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//         },
//     },
// });
//
// export const selectCart = (state) => state.cart;
//
// export const {
//     addToCart,
//     reAddToCart,
//     incrementQuantity,
//     decrementQuantity,
//     removeItem,
//     emptyCart,
//     updateItemsInCart,
//     paymentSucceeded,
//     findItemById,
//     setIsRevisedOrder,
//     setOrderInfo,
//     clearRevisedOrder,
// } = cartSlice.actions;
//
// export default cartSlice.reducer;


/**** Closest ****/


// import {createSlice, nanoid} from '@reduxjs/toolkit';
//
// const initialState = {
//     cart: [],
//     totalQuantity: 0,
//     orderNumber: nanoid(),
//     totalPrice: 0.0,
//     uniqueItemId: nanoid(),
//     selectedItems: [],
//     foundItem: {},
//     paymentSuccess: false,
//     isRevisedOrder: false,
//     revisedOrderInfo: {},
//     positionCounter: 1,
//     reservedPositionNames: [],
//     cabinetsWithCoordinates: [],
// };
//
// // Helper function to generate a position name based on counter
// const generatePositionName = (counter) => {
//     return `POS_${counter.toString().padStart(3, '0')}`;
// };
//
// // Helper function to get the next available position name
// const getNextUniquePositionName = (state) => {
//     let positionName;
//     let counter = state.positionCounter;
//
//     do {
//         positionName = generatePositionName(counter);
//         counter++;
//     } while (state.reservedPositionNames.includes(positionName));
//
//     state.positionCounter = counter;
//     state.reservedPositionNames = [...state.reservedPositionNames, positionName];
//     return positionName;
// };
//
// // Helper function to update position names after a specific position
// const updatePositionNamesAfter = (state, referencePositionName) => {
//     // Get the numeric value of the reference position
//     const refNum = parseInt(referencePositionName.split('_')[1]);
//
//     // Find all items that need their position names updated (those with position numbers >= refNum)
//     const itemsToUpdate = state.cart
//         .filter(item => {
//             const itemNum = parseInt(item.positionName.split('_')[1]);
//             return itemNum > refNum; // Only update items with higher position numbers
//         })
//         .sort((a, b) => {
//             const aNum = parseInt(a.positionName.split('_')[1]);
//             const bNum = parseInt(b.positionName.split('_')[1]);
//             return aNum - bNum;
//         });
//
//     // Update position names for affected items
//     itemsToUpdate.forEach(item => {
//         const itemNum = parseInt(item.positionName.split('_')[1]);
//         const newPositionName = generatePositionName(itemNum + 1);
//
//         // Update the item's position name
//         const cartItem = state.cart.find(i => i.itemId === item.itemId);
//         if (cartItem) {
//             // Remove old position name from reserved names
//             state.reservedPositionNames = state.reservedPositionNames.filter(
//                 name => name !== cartItem.positionName
//             );
//
//             // Update to new position name
//             cartItem.positionName = newPositionName;
//
//             // Add new position name to reserved names
//             state.reservedPositionNames.push(newPositionName);
//         }
//     });
//
//     // Update position counter to be one more than the highest position number
//     const maxNum = Math.max(
//         ...state.reservedPositionNames.map(pos => parseInt(pos.split('_')[1]))
//     );
//     state.positionCounter = maxNum + 1;
// };
//
// const cartSlice = createSlice({
//     name: 'cart',
//     initialState,
//     reducers: {
//         addToCart: (state, action) => {
//             if (action.payload) {
//                 if (action.payload.item) {
//                     const itemWithLegLevelers = {
//                         ...action.payload.item,
//                         includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
//                     };
//                     state.cart.push(itemWithLegLevelers);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 } else {
//                     const positionName = action.payload.positionName || getNextUniquePositionName(state);
//
//                     const item = {
//                         itemId: action?.payload.id,
//                         backPanel: action.payload?.backPanel || 'Inset',
//                         serialNumber: action.payload?.serialNumber || 'test',
//                         drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
//                         gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
//                         gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
//                         gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
//                         gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
//                         gapCenter: (1 / 8).toFixed(3),
//                         jointControl: action.payload?.jointControl,
//                         jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
//                         heightRange: action.payload?.heightRange,
//                         widthRange: action.payload?.widthRange,
//                         depthRange: action.payload?.depthRange,
//                         frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
//                         caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         quantity: action.payload.quantity || 1,
//                         height: action.payload?.height || action.payload?.heightRange?.split('-')[0],
//                         width: action.payload?.width || action.payload?.widthRange?.split('-')[0],
//                         depth: action.payload?.depth || action.payload?.depthRange?.split('-')[0],
//                         positionName,
//                         caseEdge: action.payload?.caseEdge,
//                         frontEdge: action.payload?.frontEdge,
//                         edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edgeBandingTop: action.payload?.edgeBandingTop || 'false',
//                         edgeBandingBottom: action.payload?.edgeBandingBottom,
//                         edgeBandingRight: action.payload?.edgeBandingRight,
//                         edgeBandingLeft: action.payload?.edgeBandingLeft,
//                         edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
//                         description: action.payload?.description,
//                         drawers: action.payload?.drawers,
//                         doors: action.payload?.doors,
//                         topDrwrHeight: action.payload?.topDrwrHeight || '1',
//                         topDrwrHeightValue: action.payload?.topDrwrHeight === '1' ? action.payload?.topDrwrHeightValue : '6',
//                         adjShelves: action.payload?.adjShelves,
//                         numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
//                         includeLegLevelers: action.payload?.includeLegLevelers || 'false',
//                         bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
//                         hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
//                         excludeFronts: action.payload?.excludeFronts || false,
//                         matBack: action.payload?.matBack,
//                         legLevelers: action.payload?.legLevelers,
//                         gapControl: action.payload?.gapControl,
//                         filterTags: action.payload?.filterTags,
//                         globalSettingsApplied: action.payload?.globalSettingsApplied || false,
//                         leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
//                         rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
//                         groupId: action.payload?.quantity > 1 ? nanoid() : null,
//                     };
//
//                     state.cart.push(item);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 }
//             }
//         },
//
//         reAddToCart: (state, action) => {
//             const positionName = action.payload.positionName || getNextUniquePositionName(state);
//             state.cart.push({
//                 ...action.payload,
//                 positionName,
//                 itemId: action.payload.itemId,
//                 quantity: Number(action.payload.quantity),
//             });
//             state.totalQuantity += parseInt(action.payload.quantity, 10);
//         },
//
//         incrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 const item = state.cart[itemIndex];
//                 item.quantity += 1;
//                 state.totalQuantity += 1;
//
//                 // Update position names for items after this one
//                 updatePositionNamesAfter(state, item.positionName);
//
//                 if (item.quantity > 1 && item.groupId === null) {
//                     item.groupId = nanoid();
//                 }
//             }
//         },
//
//         decrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
//                 const item = state.cart[itemIndex];
//                 item.quantity -= 1;
//                 state.totalQuantity -= 1;
//
//                 // Remove the last position name from reservedPositionNames
//                 const lastPositionName = state.reservedPositionNames[state.reservedPositionNames.length - 1];
//                 state.reservedPositionNames = state.reservedPositionNames.filter(name => name !== lastPositionName);
//
//                 // Update position counter
//                 state.positionCounter = Math.max(
//                     ...state.reservedPositionNames.map(pos => parseInt(pos.split('_')[1]))
//                 ) + 1;
//             }
//
//             if (state.cart[itemIndex].quantity === 1) {
//                 state.cart[itemIndex].groupId = null;
//             }
//         },
//
//         removeItem: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 const removedItem = state.cart[itemIndex];
//                 state.totalQuantity -= parseInt(removedItem.quantity, 10);
//
//                 // Remove the item's position name from reservedPositionNames
//                 state.reservedPositionNames = state.reservedPositionNames.filter(
//                     name => name !== removedItem.positionName
//                 );
//
//                 state.cart.splice(itemIndex, 1);
//
//                 // Reorder remaining position names if needed
//                 const remainingItems = state.cart.slice().sort((a, b) => {
//                     return parseInt(a.positionName.split('_')[1]) - parseInt(b.positionName.split('_')[1]);
//                 });
//
//                 // Reset position names sequentially
//                 remainingItems.forEach((item, index) => {
//                     const newPositionName = generatePositionName(index + 1);
//                     const cartItem = state.cart.find(i => i.itemId === item.itemId);
//                     if (cartItem) {
//                         cartItem.positionName = newPositionName;
//                     }
//                 });
//
//                 // Update reservedPositionNames and positionCounter
//                 state.reservedPositionNames = remainingItems.map(item => item.positionName);
//                 state.positionCounter = remainingItems.length + 1;
//             }
//
//             if (state.totalQuantity < 0) {
//                 state.totalQuantity = 0;
//             }
//             if (state.totalQuantity === 0) {
//                 state.isRevisedOrder = false;
//                 state.revisedOrderInfo = {};
//             }
//         },
//
//         emptyCart: (state) => {
//             state.cart = [];
//             state.totalQuantity = 0;
//             state.orderNumber = nanoid();
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//             state.positionCounter = 1;
//             state.reservedPositionNames = [];
//             state.cabinetsWithCoordinates = [];
//         },
//
//         updateItemsInCart: (state, action) => {
//             const updatedItems = action.payload;
//             updatedItems.forEach((updatedItem) => {
//                 const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);
//
//                 if (itemIndex >= 0) {
//                     state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
//                 }
//             });
//         },
//
//         updateCabinetsFromDrawingTool: (state, action) => {
//             const cabinets = action.payload;
//             let cabinetArray = [];
//
//             cabinets.map((cabinet) => {
//                 let item = {
//                     positionName: cabinet?.positionName,
//                     x: cabinet?.x,
//                     y: cabinet?.y,
//                     rotation: cabinet?.rotation
//                 }
//                 cabinetArray.push(item);
//             })
//
//             state.cabinetsWithCoordinates = cabinetArray;
//         },
//
//         paymentSucceeded: (state, action) => {
//             state.paymentSuccess = action.payload;
//         },
//
//         findItemById: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload);
//             state.foundItem = state.cart[itemIndex] ? {...state.cart[itemIndex]} : {};
//         },
//
//         setIsRevisedOrder: (state, action) => {
//             state.isRevisedOrder = action.payload;
//         },
//
//         setOrderInfo: (state, action) => {
//             state.revisedOrderInfo = action.payload;
//         },
//
//         clearRevisedOrder: (state) => {
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//         },
//     },
// });
//
// export const selectCart = (state) => state.cart;
//
// export const {
//     addToCart,
//     reAddToCart,
//     incrementQuantity,
//     decrementQuantity,
//     removeItem,
//     emptyCart,
//     updateItemsInCart,
//     paymentSucceeded,
//     findItemById,
//     setIsRevisedOrder,
//     setOrderInfo,
//     clearRevisedOrder,
//     updateCabinetsFromDrawingTool,
// } = cartSlice.actions;
//
// export default cartSlice.reducer;


// Going to maintain this in sections for clarity. First, let's update the helper functions.


// import {createSlice, nanoid} from '@reduxjs/toolkit';
//
// const initialState = {
//     cart: [],
//     totalQuantity: 0,
//     orderNumber: nanoid(),
//     totalPrice: 0.0,
//     uniqueItemId: nanoid(),
//     selectedItems: [],
//     foundItem: {},
//     paymentSuccess: false,
//     isRevisedOrder: false,
//     revisedOrderInfo: {},
//     positionCounter: 1,
//     reservedPositionNames: [],
//     cabinetsWithCoordinates: [],
// };
//
// // Helper function to generate a position name based on counter and prefix
// const generatePositionName = (counter, prefix = 'POS') => {
//     return `${prefix}_${counter.toString().padStart(3, '0')}`;
// };
//
// // Helper function to extract prefix and number from position name
// const parsePositionName = (positionName) => {
//     const [prefix, number] = positionName.split('_');
//     return {
//         prefix,
//         number: parseInt(number)
//     };
// };
//
// // Helper function to get the next available position name
// const getNextUniquePositionName = (state) => {
//     let positionName;
//     let counter = state.positionCounter;
//
//     do {
//         positionName = generatePositionName(counter);
//         counter++;
//     } while (state.reservedPositionNames.includes(positionName));
//
//     state.positionCounter = counter;
//     state.reservedPositionNames = [...state.reservedPositionNames, positionName];
//     return positionName;
// };
//
// // Helper function to update position names after a specific position
// const updatePositionNamesAfter = (state, referencePositionName) => {
//     const refParsed = parsePositionName(referencePositionName);
//
//     // Find all items that need their position names updated (those with position numbers > refNum)
//     const itemsToUpdate = state.cart
//         .filter(item => {
//             const itemParsed = parsePositionName(item.positionName);
//             return itemParsed.prefix === refParsed.prefix &&
//                 itemParsed.number > refParsed.number;
//         })
//         .sort((a, b) => {
//             const aNum = parsePositionName(a.positionName).number;
//             const bNum = parsePositionName(b.positionName).number;
//             return aNum - bNum;
//         });
//
//     // Update position names for affected items
//     itemsToUpdate.forEach(item => {
//         const itemParsed = parsePositionName(item.positionName);
//         const newPositionName = generatePositionName(itemParsed.number + 1, itemParsed.prefix);
//
//         // Update the item's position name
//         const cartItem = state.cart.find(i => i.itemId === item.itemId);
//         if (cartItem) {
//             // Remove old position name from reserved names
//             state.reservedPositionNames = state.reservedPositionNames.filter(
//                 name => name !== cartItem.positionName
//             );
//
//             // Update to new position name
//             cartItem.positionName = newPositionName;
//
//             // Add new position name to reserved names
//             state.reservedPositionNames.push(newPositionName);
//         }
//     });
//
//     // Update position counter to be one more than the highest position number
//     const maxNum = Math.max(
//         ...state.reservedPositionNames.map(pos => parsePositionName(pos).number)
//     );
//     state.positionCounter = maxNum + 1;
// };
//
// const cartSlice = createSlice({
//     name: 'cart',
//     initialState,
//     reducers: {
//         addToCart: (state, action) => {
//             if (action.payload) {
//                 if (action.payload.item) {
//                     const itemWithLegLevelers = {
//                         ...action.payload.item,
//                         includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
//                     };
//                     state.cart.push(itemWithLegLevelers);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 } else {
//                     const positionName = action.payload.positionName || getNextUniquePositionName(state);
//
//                     const item = {
//                         itemId: action?.payload.id,
//                         backPanel: action.payload?.backPanel || 'Inset',
//                         serialNumber: action.payload?.serialNumber || 'test',
//                         drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
//                         gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
//                         gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
//                         gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
//                         gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
//                         gapCenter: (1 / 8).toFixed(3),
//                         jointControl: action.payload?.jointControl,
//                         jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
//                         heightRange: action.payload?.heightRange,
//                         widthRange: action.payload?.widthRange,
//                         depthRange: action.payload?.depthRange,
//                         frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
//                         caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || '3/4" Pre-finished Maple',
//                         quantity: action.payload.quantity || 1,
//                         height: action.payload?.height || action.payload?.heightRange?.split('-')[0],
//                         width: action.payload?.width || action.payload?.widthRange?.split('-')[0],
//                         depth: action.payload?.depth || action.payload?.depthRange?.split('-')[0],
//                         positionName,
//                         caseEdge: action.payload?.caseEdge,
//                         frontEdge: action.payload?.frontEdge,
//                         edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
//                         edgeBandingTop: action.payload?.edgeBandingTop || 'false',
//                         edgeBandingBottom: action.payload?.edgeBandingBottom,
//                         edgeBandingRight: action.payload?.edgeBandingRight,
//                         edgeBandingLeft: action.payload?.edgeBandingLeft,
//                         edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
//                         description: action.payload?.description,
//                         drawers: action.payload?.drawers,
//                         doors: action.payload?.doors,
//                         topDrwrHeight: action.payload?.topDrwrHeight || '1',
//                         topDrwrHeightValue: action.payload?.topDrwrHeight === '1' ? action.payload?.topDrwrHeightValue : '6',
//                         adjShelves: action.payload?.adjShelves,
//                         numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
//                         includeLegLevelers: action.payload?.includeLegLevelers || 'false',
//                         bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
//                         hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
//                         excludeFronts: action.payload?.excludeFronts || false,
//                         matBack: action.payload?.matBack,
//                         legLevelers: action.payload?.legLevelers,
//                         gapControl: action.payload?.gapControl,
//                         filterTags: action.payload?.filterTags,
//                         globalSettingsApplied: action.payload?.globalSettingsApplied || false,
//                         leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
//                         rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
//                         groupId: action.payload?.quantity > 1 ? nanoid() : null,
//                     };
//
//                     state.cart.push(item);
//                     state.totalQuantity += parseInt(action.payload.quantity, 10);
//                 }
//             }
//         },
//
//         reAddToCart: (state, action) => {
//             const positionName = action.payload.positionName || getNextUniquePositionName(state);
//             state.cart.push({
//                 ...action.payload,
//                 positionName,
//                 itemId: action.payload.itemId,
//                 quantity: Number(action.payload.quantity),
//             });
//             state.totalQuantity += parseInt(action.payload.quantity, 10);
//         },
//
//         incrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 const item = state.cart[itemIndex];
//                 item.quantity += 1;
//                 state.totalQuantity += 1;
//
//                 // Update position names for items after this one
//                 updatePositionNamesAfter(state, item.positionName);
//
//                 if (item.quantity > 1 && item.groupId === null) {
//                     item.groupId = nanoid();
//                 }
//             }
//         },
//
//         decrementQuantity: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
//                 const item = state.cart[itemIndex];
//                 const itemParsed = parsePositionName(item.positionName);
//                 item.quantity -= 1;
//                 state.totalQuantity -= 1;
//
//                 // Remove the last position name from reservedPositionNames that matches the prefix
//                 const lastPositionWithPrefix = state.reservedPositionNames
//                     .filter(name => parsePositionName(name).prefix === itemParsed.prefix)
//                     .sort((a, b) => parsePositionName(b).number - parsePositionName(a).number)[0];
//
//                 if (lastPositionWithPrefix) {
//                     state.reservedPositionNames = state.reservedPositionNames.filter(
//                         name => name !== lastPositionWithPrefix
//                     );
//                 }
//
//                 // Update position counter based on prefix
//                 const maxNumForPrefix = Math.max(
//                     ...state.reservedPositionNames
//                         .filter(name => parsePositionName(name).prefix === itemParsed.prefix)
//                         .map(name => parsePositionName(name).number)
//                 );
//                 state.positionCounter = maxNumForPrefix + 1;
//             }
//
//             if (state.cart[itemIndex].quantity === 1) {
//                 state.cart[itemIndex].groupId = null;
//             }
//         },
//
//         removeItem: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
//             if (itemIndex >= 0) {
//                 const removedItem = state.cart[itemIndex];
//                 const removedItemParsed = parsePositionName(removedItem.positionName);
//                 state.totalQuantity -= parseInt(removedItem.quantity, 10);
//
//                 // Remove the item's position name from reservedPositionNames
//                 state.reservedPositionNames = state.reservedPositionNames.filter(
//                     name => name !== removedItem.positionName
//                 );
//
//                 state.cart.splice(itemIndex, 1);
//
//                 // Reorder remaining position names if needed, preserving prefixes
//                 const remainingItemsByPrefix = {};
//                 state.cart.forEach(item => {
//                     const parsed = parsePositionName(item.positionName);
//                     if (!remainingItemsByPrefix[parsed.prefix]) {
//                         remainingItemsByPrefix[parsed.prefix] = [];
//                     }
//                     remainingItemsByPrefix[parsed.prefix].push(item);
//                 });
//
//                 // Reset reservedPositionNames
//                 state.reservedPositionNames = [];
//
//                 // Update position names for each prefix group
//                 Object.entries(remainingItemsByPrefix).forEach(([prefix, items]) => {
//                     items.sort((a, b) => parsePositionName(a.positionName).number - parsePositionName(b.positionName).number)
//                         .forEach((item, index) => {
//                             const newPositionName = generatePositionName(index + 1, prefix);
//                             const cartItem = state.cart.find(i => i.itemId === item.itemId);
//                             if (cartItem) {
//                                 cartItem.positionName = newPositionName;
//                                 state.reservedPositionNames.push(newPositionName);
//                             }
//                         });
//                 });
//
//                 // Update position counter
//                 if (state.cart.length > 0) {
//                     const maxNum = Math.max(
//                         ...state.reservedPositionNames.map(pos => parsePositionName(pos).number)
//                     );
//                     state.positionCounter = maxNum + 1;
//                 } else {
//                     state.positionCounter = 1;
//                 }
//             }
//
//             if (state.totalQuantity < 0) {
//                 state.totalQuantity = 0;
//             }
//             if (state.totalQuantity === 0) {
//                 state.isRevisedOrder = false;
//                 state.revisedOrderInfo = {};
//             }
//         },
//
//         emptyCart: (state) => {
//             state.cart = [];
//             state.totalQuantity = 0;
//             state.orderNumber = nanoid();
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//             state.positionCounter = 1;
//             state.reservedPositionNames = [];
//             state.cabinetsWithCoordinates = [];
//         },
//
//         updateItemsInCart: (state, action) => {
//             const updatedItems = action.payload;
//             updatedItems.forEach((updatedItem) => {
//                 const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);
//
//                 if (itemIndex >= 0) {
//                     state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
//                 }
//             });
//         },
//
//         updateCabinetsFromDrawingTool: (state, action) => {
//             const cabinets = action.payload;
//             let cabinetArray = [];
//
//             cabinets.map((cabinet) => {
//                 let item = {
//                     positionName: cabinet?.positionName,
//                     x: cabinet?.x,
//                     y: cabinet?.y,
//                     rotation: cabinet?.rotation
//                 }
//                 cabinetArray.push(item);
//             })
//
//             state.cabinetsWithCoordinates = cabinetArray;
//         },
//
//         paymentSucceeded: (state, action) => {
//             state.paymentSuccess = action.payload;
//         },
//
//         findItemById: (state, action) => {
//             const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload);
//             state.foundItem = state.cart[itemIndex] ? {...state.cart[itemIndex]} : {};
//         },
//
//         setIsRevisedOrder: (state, action) => {
//             state.isRevisedOrder = action.payload;
//         },
//
//         setOrderInfo: (state, action) => {
//             state.revisedOrderInfo = action.payload;
//         },
//
//         clearRevisedOrder: (state) => {
//             state.isRevisedOrder = false;
//             state.revisedOrderInfo = {};
//         },
//     },
// });
//
// export const selectCart = (state) => state.cart;
//
// export const {
//     addToCart,
//     reAddToCart,
//     incrementQuantity,
//     decrementQuantity,
//     removeItem,
//     emptyCart,
//     updateItemsInCart,
//     paymentSucceeded,
//     findItemById,
//     setIsRevisedOrder,
//     setOrderInfo,
//     clearRevisedOrder,
//     updateCabinetsFromDrawingTool,
// } = cartSlice.actions;
//
// export default cartSlice.reducer;


import {createSlice, nanoid} from '@reduxjs/toolkit';

const initialState = {
    cart: [],
    totalQuantity: 0,
    orderNumber: nanoid(),
    totalPrice: 0.0,
    uniqueItemId: nanoid(),
    selectedItems: [],
    foundItem: {},
    paymentSuccess: false,
    isRevisedOrder: false,
    revisedOrderInfo: {},
    positionCounter: 1,
    reservedPositionNames: [],
    cabinetsWithCoordinates: [],
    highestPositionNumbers: {}, // Initialize as empty object
};

// Helper function to generate a position name based on counter and prefix
const generatePositionName = (counter, prefix = 'POS') => {
    return `${prefix}_${counter.toString().padStart(3, '0')}`;
};

// Helper function to extract prefix and number from position name
const parsePositionName = (positionName) => {
    const [prefix, number] = positionName.split('_');
    return {
        prefix,
        number: parseInt(number)
    };
};

// Helper function to get the next available position name
const getNextUniquePositionName = (state, prefix = 'POS') => {
    // Initialize highestPositionNumbers if it doesn't exist
    if (!state.highestPositionNumbers) {
        state.highestPositionNumbers = {};
    }

    let counter = state.highestPositionNumbers[prefix] || 1;
    let positionName;

    do {
        positionName = generatePositionName(counter, prefix);
        counter++;
    } while (state.reservedPositionNames.includes(positionName));

    state.highestPositionNumbers[prefix] = counter;
    state.reservedPositionNames.push(positionName);
    state.reservedPositionNames.sort();
    return positionName;
};

// Helper function to update position names after a specific position
const updatePositionNamesAfter = (state, referencePositionName) => {
    const refParsed = parsePositionName(referencePositionName);

    // Initialize highestPositionNumbers if it doesn't exist
    if (!state.highestPositionNumbers) {
        state.highestPositionNumbers = {};
    }

    // Find all items that need their position names updated
    const itemsToUpdate = state.cart
        .filter(item => {
            const itemParsed = parsePositionName(item.positionName);
            return itemParsed.prefix === refParsed.prefix &&
                itemParsed.number > refParsed.number;
        })
        .sort((a, b) => {
            const aNum = parsePositionName(a.positionName).number;
            const bNum = parsePositionName(b.positionName).number;
            return aNum - bNum;
        });


    // Update position names for affected items
    itemsToUpdate.forEach(item => {
        const itemParsed = parsePositionName(item.positionName);
        const newPositionName = generatePositionName(itemParsed.number + 1, itemParsed.prefix);

        const cartItem = state.cart.find(i => i.itemId === item.itemId);
        if (cartItem) {
            // Remove old position name
            state.reservedPositionNames = state.reservedPositionNames.filter(
                name => name !== cartItem.positionName
            );

            // Update to new position name
            cartItem.positionName = newPositionName;

            // Add new position name
            state.reservedPositionNames.push(newPositionName);
        }
    });

    // Sort reservedPositionNames
    state.reservedPositionNames.sort();

    // Update highest number for prefix
    if (itemsToUpdate.length > 0) {
        const maxNum = Math.max(...itemsToUpdate.map(item =>
            parsePositionName(item.positionName).number
        ));
        state.highestPositionNumbers[refParsed.prefix] = maxNum + 1;
    }
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            if (action.payload) {
                if (action.payload.item) {
                    const itemWithLegLevelers = {
                        ...action.payload.item,
                        includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
                    };
                    state.cart.push(itemWithLegLevelers);
                    state.totalQuantity += parseInt(action.payload.quantity, 10);
                } else {
                    // Ensure highestPositionNumbers exists
                    if (!state.highestPositionNumbers) {
                        state.highestPositionNumbers = {};
                    }

                    const positionName = action.payload.positionName || getNextUniquePositionName(state);

                    const item = {
                        itemId: action?.payload.id,
                        backPanel: action.payload?.backPanel || 'Inset',
                        serialNumber: action.payload?.serialNumber || 'test',
                        drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
                        gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
                        gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
                        gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
                        gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
                        gapCenter: (1 / 8).toFixed(3),
                        jointControl: action.payload?.jointControl,
                        jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
                        heightRange: action.payload?.heightRange,
                        widthRange: action.payload?.widthRange,
                        depthRange: action.payload?.depthRange,
                        frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
                        caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
                        backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || '3/4" Pre-finished Maple',
                        quantity: action.payload.quantity || 1,
                        height: action.payload?.height || action.payload?.heightRange?.split('-')[0],
                        width: action.payload?.width || action.payload?.widthRange?.split('-')[0],
                        depth: action.payload?.depth || action.payload?.depthRange?.split('-')[0],
                        positionName,
                        caseEdge: action.payload?.caseEdge,
                        frontEdge: action.payload?.frontEdge,
                        edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edgeBandingTop: action.payload?.edgeBandingTop || 'false',
                        edgeBandingBottom: action.payload?.edgeBandingBottom,
                        edgeBandingRight: action.payload?.edgeBandingRight,
                        edgeBandingLeft: action.payload?.edgeBandingLeft,
                        edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
                        description: action.payload?.description,
                        drawers: action.payload?.drawers,
                        doors: action.payload?.doors,
                        topDrwrHeight: action.payload?.topDrwrHeight || '1',
                        topDrwrHeightValue: action.payload?.topDrwrHeight === '1' ? action.payload?.topDrwrHeightValue : '6',
                        adjShelves: action.payload?.adjShelves,
                        numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
                        includeLegLevelers: action.payload?.includeLegLevelers || 'false',
                        bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
                        hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
                        excludeFronts: action.payload?.excludeFronts || false,
                        matBack: action.payload?.matBack,
                        legLevelers: action.payload?.legLevelers,
                        gapControl: action.payload?.gapControl,
                        filterTags: action.payload?.filterTags,
                        globalSettingsApplied: action.payload?.globalSettingsApplied || false,
                        leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
                        rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
                        groupId: action.payload?.quantity > 1 ? nanoid() : null,
                    };

                    state.cart.push(item);
                    state.totalQuantity += parseInt(action.payload.quantity, 10);

                    // Update highestPositionNumbers for the prefix
                    const {prefix, number} = parsePositionName(positionName);
                    state.highestPositionNumbers[prefix] = Math.max(
                        state.highestPositionNumbers[prefix] || 0,
                        number + 1
                    );

                    if (action.payload?.quantity > 1) {
                        for (let i = 0; i < action.payload?.quantity; i++) {
                            getNextUniquePositionName(state)
                        }
                    }

                }
            }
        },

        reAddToCart: (state, action) => {
            // Ensure highestPositionNumbers exists
            if (!state.highestPositionNumbers) {
                state.highestPositionNumbers = {};
            }

            const positionName = action.payload.positionName || getNextUniquePositionName(state);
            const {prefix, number} = parsePositionName(positionName);

            state.cart.push({
                ...action.payload,
                positionName,
                itemId: action.payload.itemId,
                quantity: Number(action.payload.quantity),
            });

            state.totalQuantity += parseInt(action.payload.quantity, 10);

            // Update highestPositionNumbers
            state.highestPositionNumbers[prefix] = Math.max(
                state.highestPositionNumbers[prefix] || 0,
                number + 1
            );
        },

        incrementQuantity: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
            if (itemIndex >= 0) {
                // Ensure highestPositionNumbers exists
                if (!state.highestPositionNumbers) {
                    state.highestPositionNumbers = {};
                }

                const item = state.cart[itemIndex];
                const {prefix, number} = parsePositionName(item.positionName);

                // Increment quantity
                item.quantity += 1;
                state.totalQuantity += 1;

                // Update position names for higher numbered items
                updatePositionNamesAfter(state, item.positionName);

                // Update highest number for this prefix
                state.highestPositionNumbers[prefix] = Math.max(
                    state.highestPositionNumbers[prefix] || 0,
                    number + item.quantity
                );

                if (item.quantity > 1 && item.groupId === null) {
                    item.groupId = nanoid();
                }
            }
        },

        decrementQuantity: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
            if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
                // Ensure highestPositionNumbers exists
                if (!state.highestPositionNumbers) {
                    state.highestPositionNumbers = {};
                }

                const item = state.cart[itemIndex];
                const {prefix, number} = parsePositionName(item.positionName);

                // Calculate position name to remove
                const positionToRemove = generatePositionName(number + item.quantity - 1, prefix);

                // Decrement quantity
                item.quantity -= 1;
                state.totalQuantity -= 1;

                // Remove position name from reserved list
                state.reservedPositionNames = state.reservedPositionNames.filter(
                    name => name !== positionToRemove
                );

                // Update highest number for this prefix
                const highestRemaining = Math.max(
                    ...state.reservedPositionNames
                        .filter(name => parsePositionName(name).prefix === prefix)
                        .map(name => parsePositionName(name).number)
                );

                state.highestPositionNumbers[prefix] = highestRemaining + 1;

                if (item.quantity === 1) {
                    item.groupId = null;
                }
            }
        },

        removeItem: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
            if (itemIndex >= 0) {
                const removedItem = state.cart[itemIndex];
                const {prefix} = parsePositionName(removedItem.positionName);

                // Update total quantity
                state.totalQuantity -= parseInt(removedItem.quantity, 10);

                // Remove the item's position name from reservedPositionNames
                state.reservedPositionNames = state.reservedPositionNames.filter(
                    name => name !== removedItem.positionName
                );

                // Remove the item
                state.cart.splice(itemIndex, 1);

                // Update highestPositionNumbers if needed
                if (state.cart.length > 0) {
                    const remainingWithPrefix = state.cart.filter(
                        item => parsePositionName(item.positionName).prefix === prefix
                    );

                    if (remainingWithPrefix.length > 0) {
                        const maxNum = Math.max(
                            ...remainingWithPrefix.map(
                                item => parsePositionName(item.positionName).number
                            )
                        );
                        state.highestPositionNumbers[prefix] = maxNum + 1;
                    } else {
                        delete state.highestPositionNumbers[prefix];
                    }
                }

                if (state.totalQuantity <= 0) {
                    state.totalQuantity = 0;
                    state.isRevisedOrder = false;
                    state.revisedOrderInfo = {};
                    state.highestPositionNumbers = {};
                }
            }
        },

        emptyCart: (state) => {
            state.cart = [];
            state.totalQuantity = 0;
            state.orderNumber = nanoid();
            state.isRevisedOrder = false;
            state.revisedOrderInfo = {};
            state.positionCounter = 1;
            state.reservedPositionNames = [];
            state.cabinetsWithCoordinates = [];
            state.highestPositionNumbers = {};
        },

        updateItemsInCart: (state, action) => {
            const updatedItems = action.payload;
            updatedItems.forEach((updatedItem) => {
                const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);

                if (itemIndex >= 0) {
                    state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
                }
            });
        },

        updateCabinetsFromDrawingTool: (state, action) => {
            const cabinets = action?.payload;
            let cabinetArray = [];

            cabinets.map((cabinet) => {
                let item = {
                    positionName: cabinet?.positionName,
                    x: cabinet?.x,
                    y: cabinet?.y,
                    rotation: cabinet?.rotation
                }
                cabinetArray.push(item);
            })

            state.cabinetsWithCoordinates = cabinetArray;
        },

        paymentSucceeded: (state, action) => {
            state.paymentSuccess = action.payload;
        },

        findItemById: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload);
            state.foundItem = state.cart[itemIndex] ? {...state.cart[itemIndex]} : {};
        },

        setIsRevisedOrder: (state, action) => {
            state.isRevisedOrder = action.payload;
        },

        setOrderInfo: (state, action) => {
            state.revisedOrderInfo = action.payload;
        },

        clearRevisedOrder: (state) => {
            state.isRevisedOrder = false;
            state.revisedOrderInfo = {};
        },
    },
});

export const selectCart = (state) => state.cart;

export const {
    addToCart,
    reAddToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    emptyCart,
    updateItemsInCart,
    paymentSucceeded,
    findItemById,
    setIsRevisedOrder,
    setOrderInfo,
    clearRevisedOrder,
    updateCabinetsFromDrawingTool,
} = cartSlice.actions;

export default cartSlice.reducer;